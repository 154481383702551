import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/Logo";
import { UIContext, FooterTypes } from "../../context/UIContext";
import RequestKit from "../../components/RequestKit/RequestKit";
import FooterSearch from "../../components/FooterSearch/FooterSearch";
import FooterContact from "../../components/FooterContact/FooterContact";

import Facebook from "../../images/Facebook";
import Twitter from "../../images/Twitter";
import Instagram from "../../images/Instagram";

const Paypal = require("../../images/paypal.png");
const MasterCard = require("../../images/mastercard.png");
const Visa = require("../../images/visa.png");

import "./styles.css";

export default function Footer() {
  const { footerType } = useContext(UIContext);

  function displayContent() {
    if (footerType === FooterTypes.KIT) {
      return <RequestKit />;
    } else if (footerType === FooterTypes.SEARCH) {
      return <FooterSearch />;
    } else if (
      footerType === FooterTypes.CONTACT ||
      footerType === FooterTypes.NOTFOUND
    ) {
      return <FooterContact />;
    }
  }

  return (
    <>
      <div className="footer">
        {displayContent()}
        <div className="footerLinks">
          <div className="linkList listLeft">
            <p className="white centered bold">About</p>
            <ul>
              <li className="white centered">
                <Link to="/about" className="white centered">
                  Company
                </Link>
              </li>
              <li className="white centered">
                <Link to="/terms" className="white centered">
                  Terms of Service
                </Link>
              </li>
              <li className="white centered">
                <Link to="/contact" className="white centered">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="footerLogoBox">
            <Logo className="footerLogo" color="#fff" height={60} />
            <p className="white">
              Made with <span className="maroon">&#9829;</span> by{" "}
              <a href="https://crafted.design" className="white bold">
                Crafted
              </a>
            </p>
          </div>

          <div className="linkList listRight">
            <p className="white centered bold">Contact Us</p>
            <ul>
              <li className="white centered">
                <a href="tel:+1 (251) 277-9434">+1 (251) 277-9434</a>
              </li>
              <li className="white centered">
                <a href="mailto:sales@slickpackages.com">
                  sales@slickpackages.com
                </a>
              </li>
              <li className="white centered footerSocialIcons">
                <a href="https://facebook.com">
                  <Facebook className="footerFacebook" />
                </a>
                <a href="https://instagram.com">
                  <Instagram className="footerInstagram" />
                </a>
                <a href="https://twitter.com">
                  <Twitter className="footerTwitter" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footerBottom">
          <div className="white centered">
            Copyrights 2021. All Rights Reserved.
          </div>
          <div className="white centered paymentOptions">
            <div className="paymentOption">
              <img src={Paypal} height={18} alt="Payment_Paypal" />
            </div>
            <div className="paymentOption">
              <img src={MasterCard} height={18} alt="Payment_Mastercard" />
            </div>
            <div className="paymentOption">
              <img src={Visa} height={18} alt="Payment_Visa" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
