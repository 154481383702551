import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UIContext, FooterTypes } from "../../context/UIContext";

import "./styles.css";

export default function FooterContact() {
  const { footerType } = useContext(UIContext);

  return (
    <div className="footerContact">
      <h1 className="h1 white">
        {footerType === FooterTypes.NOTFOUND
          ? "Didn't find what you were looking for?"
          : "Wish to contact us first?"}
      </h1>
      <Link to="/contact" className="secondaryContactLink canary">
        <button>Contact Us</button>
      </Link>
    </div>
  );
}
