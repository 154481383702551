import React, { useState, useContext, useEffect } from "react";
import { UIContext, FooterTypes } from "../../context/UIContext";
import { Link, useHistory } from "react-router-dom";
import SearchIcon from "../../images/SearchIcon";
import SearchGoIcon from "../../images/SearchGoIcon";
import {
  DesignCollection,
  IndustryCollection,
  IndustrySubCategories,
  MaterialCollection,
} from "../../data/firebase";

import "./styles.css";

export default function Search({
  match: {
    params: { query },
  },
}) {
  const { setFooterType, closeLoader } = useContext(UIContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const BY_DESIGN = "by design";
  const BY_INDUSTRY = "by industry";
  const BY_MATERIAL = "by material";

  useEffect(() => {
    setFooterType(FooterTypes.NOTFOUND);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      closeLoader();
    }
  }, [isLoading]);

  useEffect(() => {
    if (query) {
      setSearchQuery(query);
      search(query);
    }
  }, [query]);

  function search(q) {
    setIsLoading(true);
    const allResults = [];
    searchDesign(q).then((results) => {
      allResults.push(...results);
      searchMaterial(q).then((results) => {
        allResults.push(...results);
        searchIndustry(q).then((results) => {
          allResults.push(...results);
          searchIndustrySubCategories(q).then((results) => {
            allResults.push(...results);
            setIsLoading(false);
            setSearchResults([...allResults]);
          });
        });
      });
    });
  }

  function searchDesign(q) {
    return new Promise((resolve) => {
      let results = [];
      DesignCollection.where(
        "keywords",
        "array-contains",
        searchQuery ? searchQuery.toLowerCase() : q
      )
        .get()
        .then((snapshot) => {
          results = snapshot.docs.map((doc) => ({
            id: doc.id,
            breadCrumbs: ["home", BY_DESIGN],
            ...doc.data(),
          }));
          resolve(results);
        });
    });
  }

  function searchMaterial(q) {
    return new Promise((resolve) => {
      let results = [];
      MaterialCollection.where(
        "keywords",
        "array-contains",
        searchQuery ? searchQuery.toLowerCase() : q
      )
        .get()
        .then((snapshot) => {
          results = snapshot.docs.map((doc) => ({
            id: doc.id,
            breadCrumbs: ["home", BY_MATERIAL],
            ...doc.data(),
          }));
          resolve(results);
        });
    });
  }

  function searchIndustry(q) {
    return new Promise((resolve) => {
      let results = [];
      IndustryCollection.where(
        "keywords",
        "array-contains",
        searchQuery ? searchQuery.toLowerCase() : q
      )
        .get()
        .then((snapshot) => {
          results = snapshot.docs.map((doc) => ({
            id: doc.id,
            breadCrumbs: ["home", BY_INDUSTRY],
            ...doc.data(),
          }));
          resolve(results);
        });
    });
  }

  function searchIndustrySubCategories(q) {
    return new Promise((resolve) => {
      let results = [];
      IndustrySubCategories.where(
        "keywords",
        "array-contains",
        searchQuery ? searchQuery.toLowerCase() : q
      )
        .get()
        .then((snapshot) => {
          results = snapshot.docs.map((doc) => ({
            id: doc.id,
            breadCrumbs: ["home", BY_INDUSTRY, "cosmetics"],
            ...doc.data(),
          }));
          resolve(results);
        });
    });
  }

  function getPath(linkArray) {
    if (linkArray[1] === BY_DESIGN) {
      return "/byDesign";
    } else if (linkArray[1] === BY_MATERIAL) {
      return "/byMaterial";
    } else if (linkArray[2]) {
      return `/byIndustry/${linkArray[2]}`;
    } else if (linkArray[1] === BY_INDUSTRY) {
      return "/byIndustry";
    }
  }

  return (
    <div className="searchPageContainer">
      <div className="searchBar">
        <form
          className="searchPageForm"
          onSubmit={(event) => {
            event.preventDefault();
            history.push(`/search/${searchQuery}`);
          }}
        >
          <div className="icon">
            <SearchIcon />
          </div>
          <input
            type="text"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                search();
              }
            }}
          />
          <div
            className="icon pointer"
            role="button"
            onClick={search}
            onKeyPress={search}
            tabIndex="-1"
          >
            <SearchGoIcon />
          </div>
        </form>
      </div>
      {isLoading === true ? (
        <div className="loading">Searching...</div>
      ) : isLoading === false ? (
        <div className="searchResults">
          <h1 className="h1 left">Search Results</h1>
          <p>
            {searchResults.length} results found for{" "}
            <span className="bold">{searchQuery}</span>
          </p>
          {searchResults.map((r, index) => (
            <Link to={`${getPath(r.breadCrumbs)}/${r.id}`} key={r.id}>
              <div className="searchResult" key={index.toString()}>
                <h2 className="searchResultTitle h2 left">{r.title}</h2>
                <p className="crumbs caption bold">
                  {`${r.breadCrumbs[0]} > ${r.breadCrumbs[1]} ${
                    r.breadCrumbs[2] ? " > " + r.breadCrumbs[2] : ""
                  }`}
                </p>
                <p className="searchResultDescription">{r.description}</p>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className="loading">Type in the search bar to find a box</div>
      )}
    </div>
  );
}
