import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { UIContext, FooterTypes } from "../../context/UIContext";
import CompanyAttributes from "../../components/CompanyAttributes/CompanyAttributes";
import ReviewCarousel from "../../components/ReviewCarousel/ReviewCarousel";

import "./styles.css";

export default function WhyUs() {
  const { setFooterType, closeLoader } = useContext(UIContext);

  useEffect(() => {
    setFooterType(FooterTypes.KIT);
    closeLoader();
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Custom Packaging For Small Business | Cardboard Boxes Manufacturers
        </title>
        <meta
          property="og:title"
          content="Custom Packaging For Small Business | Cardboard Boxes Manufacturers"
        />
        <meta
          charSet="utf-8"
          content="Slick Packages offers high-quality Custom Packaging For Small Business as one of the good Cardboard Boxes Manufacturers."
        />
        <meta
          property="og:description"
          content="Slick Packages offers high-quality Custom Packaging For Small Business as one of the good Cardboard Boxes Manufacturers."
        />
        <meta
          name="keywords"
          content="Custom Packaging For Small Business, Cardboard Boxes Manufacturers"
        />
        <link rel="canonical" href="https://slickpackages.com/why-choose-us" />
        <meta
          property="og:url"
          content="https://slickpackages.com/why-choose-us"
        />
      </Helmet>
      <div className="whyChooseUsHero">
        <h1 className="h1">Don&apos;t Settle For Less</h1>
        <div className="whyChooseUsText">
          <p className="bold caption">We Have a Devoted Team</p>
          <p>
            to ensure your products are delivered in top-notch packaging to your
            customers
          </p>
        </div>
      </div>
      <div className="whyChooseUsAttributes">
        <div className="whyChooseUsAttribute">
          <p className="h1 caption">BOX DESIGN DEVELOPMENT</p>
          <p>
            Submit your specifications and requirements for custom packaging and
            our designers instantly get to work. Once we are satisfied with the
            quality ourselves, we send you a prototype to give a go ahead before
            we start mass printing.
          </p>
        </div>
        <div className="whyChooseUsAttribute">
          <p className="h1 caption">MATCHING CUSTOMERS NEEDS</p>
          <p>
            We provide dedicated point of contact while your order is in
            progress. Give your ideas, provide designs or just stay up to date
            throughout the process and make sure everything is up to your
            standards.
          </p>
        </div>
        <div className="whyChooseUsAttribute">
          <p className="h1 caption">QUALITY DELIVERED ON TIME</p>
          <p>
            We value time, and make sure to deliver your order within 15
            business days. Have a tight schedule? No worries! Let us know and we
            can bump-up your package to deliver within 7 days!
          </p>
        </div>
      </div>
      <CompanyAttributes optionalTitle="Still Not Convinced?" />
      {/* <ReviewCarousel /> */}
    </>
  );
}
