import React, { useContext } from "react";
import { UIContext } from "../../context/UIContext";

import "./styles.css";

export default function Loading() {
  const { loadingDirection } = useContext(UIContext);
  return (
    <>
      <div className={`layer layer--1 layer-slide-${loadingDirection}`}></div>
      <div className={`layer layer--2 layer-slide-${loadingDirection}`}></div>
      <div className={`layer layer--3 layer-slide-${loadingDirection}`}></div>
    </>
  );
}
