import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";

import { UIContext, FooterTypes } from "../../context/UIContext";
import Social from "../../components/Social/Social";

import "./styles.css";

export default function About() {
  const { setFooterType, closeLoader } = useContext(UIContext);

  useEffect(() => {
    setFooterType(FooterTypes.KIT);
    closeLoader();
  }, []);
  return (
    <>
      <Helmet>
        <title>Custom Packaging For Products | Custom Box For Product</title>
        <meta
          property="og:title"
          content="About Us - Slick Packages | Custom Printed Boxes no Minimum"
        />
        <meta
          charSet="utf-8"
          content="Slick Packages offers a range of Custom Packaging For Products and Custom Box For Product, know more about us here."
        />
        <meta
          property="og:description"
          content="Slick Packages offers a range of Custom Packaging For Products and Custom Box For Product, know more about us here."
        />
        <meta
          name="keywords"
          content="Custom Packaging For Products, Custom Box For Product"
        />
        <link rel="canonical" href="https://slickpackages.com/about" />
        <meta property="og:url" content="https://slickpackages.com/about" />
      </Helmet>
      <div className="aboutUsContainer">
        <h1 className="h1">About Slick Packages</h1>
        <div className="aboutUsText">
          <p className="bold caption">Who Are We?</p>
          <p>
            Slick Packages helps businesses carve their brand identity by
            providing inspirational and unique custom packaging for products.
            Our bespoke services, along with prompt customer support, ensure
            your packages are according to your need and stand out in the
            market.
          </p>
        </div>
        <div className="aboutUsText">
          <p className="bold caption">what is our vision/mission?</p>
          <p>
            Our mission is to become a symbol of quality in the packaging
            industry by designing custom packaging for products and printed on
            high quality paper in affordable prices.
          </p>
        </div>
      </div>
      <Social />
    </>
  );
}
