import React, { useRef } from "react";
import BoxCarousel from "../BoxCarousel/BoxCarousel";
import "./styles.css";

export default function BoxDetails({ box }) {
  const aboutHeading = useRef(null);
  const aboutBox = useRef(null);
  const boxAttributesHeading = useRef(null);
  const boxAttributes = useRef(null);

  function showDetails() {
    aboutHeading.current.className = "selected";
    aboutBox.current.className = "aboutBox";
    boxAttributesHeading.current.className = "";
    boxAttributes.current.className = "specs hidden";
  }

  function showSpecs() {
    aboutHeading.current.className = "";
    aboutBox.current.className = "aboutBox hidden";
    boxAttributesHeading.current.className = "selected";
    boxAttributes.current.className = "specs";
  }

  return (
    <>
      <div className="productContainer">
        <BoxCarousel images={box.slider_images} />
        <div className="productDetails">
          <h1 className="h1 left productTitle">{box.name}</h1>
          <div className="detailsWrapper">
            <div className="detailsHeadings">
              <span
                id="aboutProduct"
                ref={aboutHeading}
                className="selected"
                onClick={() => showDetails()}
                onKeyUp={() => showDetails()}
                role="button"
                tabIndex="-1"
              >
                About
              </span>
              <span
                id="productSpecs"
                ref={boxAttributesHeading}
                onClick={() => showSpecs()}
                onKeyUp={() => showSpecs()}
                role="button"
                tabIndex="-1"
              >
                Specifications
              </span>
            </div>
            <div className="detailsContent">
              <div ref={aboutBox} className="aboutBox">
                {box.description}
              </div>
              <div ref={boxAttributes} className="specs hidden">
                {box.specifications.map((s) => (
                  <div className="boxAttribute" key={s.title}>
                    <p className="bold">{s.title}</p>
                    <p>{s.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
