import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SearchIcon from "../../images/SearchIcon";

import "./styles.css";

export default function FooterSearch() {
  const history = useHistory();
  const [footerSearchBarValue, setFooterSearchBarValue] = useState("");
  return (
    <div className="footerSearch">
      <h2 className="h1 white">Didn&apos;t find what you were looking for?</h2>
      <p className="white centered">No problem. Try our search feature.</p>
      <div className="secondarySearch">
        <div className="searchField">
          <SearchIcon />
          <input
            type="text"
            className="searchFieldInput"
            value={footerSearchBarValue}
            onChange={(event) => setFooterSearchBarValue(event.target.value)}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                if (footerSearchBarValue) {
                  history.push(`/search/${footerSearchBarValue}`);
                  setFooterSearchBarValue("");
                }
              }
            }}
          />
        </div>
        <span
          role="button"
          tabIndex="-1"
          onKeyPress={() => {
            if (footerSearchBarValue) {
              history.push(`/search/${footerSearchBarValue}`);
              setFooterSearchBarValue("");
            }
          }}
          onClick={() => {
            if (footerSearchBarValue) {
              history.push(`/search/${footerSearchBarValue}`);
              setFooterSearchBarValue("");
            }
          }}
          className="secondaryContactLink"
        >
          <button className="searchButton">Search</button>
        </span>
      </div>
      <Link to="/contact" className="secondaryContactLink canary">
        or Contact Us
      </Link>
    </div>
  );
}
