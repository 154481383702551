import React from "react";

function FloatingQuoteIcon(props) {
  const fill = props.isdark === "true" ? "#ffffff" : "#4b3ed2";
  const stroke = props.isdark === "true" ? "#4b3ed2" : "#ffffff";
  return (
    <svg
      width={66}
      height={66}
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#prefix__filter0_d)">
        <path
          d="M33 57c14.912 0 27-12.088 27-27S47.912 3 33 3 6 15.088 6 30s12.088 27 27 27z"
          fill={fill}
        />
      </g>
      <path
        d="M31.14 20.868a.538.538 0 000-1.076 11.31 11.31 0 00-11.3 11.297v3.228a5.935 5.935 0 101.085-3.4A10.229 10.229 0 0131.14 20.868zM39.746 28.399a5.911 5.911 0 00-4.833 2.514 10.23 10.23 0 0110.213-10.046.539.539 0 100-1.076 11.31 11.31 0 00-11.3 11.3v3.228a5.918 5.918 0 105.918-5.918l.002-.002z"
        fill={stroke}
      />
      <defs>
        <filter
          id="prefix__filter0_d"
          x={0}
          y={0}
          width={66}
          height={66}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default FloatingQuoteIcon;
