import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { UIContext, FooterTypes } from "../../context/UIContext";

import Social from "../../components/Social/Social";

import "./styles.css";

export default function TnC() {
  const { setFooterType, closeLoader } = useContext(UIContext);

  useEffect(() => {
    setFooterType(FooterTypes.KIT);
    closeLoader();
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Terms of Services of Slick Packages - Cardboard Boxes Manufacturers
        </title>
        <meta
          property="og:title"
          content="Terms of Services of Slick Packages - Cardboard Boxes Manufacturers"
        />
        <meta
          charSet="utf-8"
          content="Terms of Services of Slick Packages for custom printed soap boxes, custom packaging for small businesses and products at wholesale rate."
        />
        <meta
          property="og:description"
          content="Terms of Services of Slick Packages for custom printed soap boxes, custom packaging for small businesses and products at wholesale rate. | Slick Packages"
        />
        <link rel="canonical" href="https://slickpackages.com/terms" />
        <meta property="og:url" content="https://slickpackages.com/terms" />
      </Helmet>
      <div className="termsContainer">
        <h1 className="h1">Our Terms of Service</h1>
        <div className="termsText">
          <p>
            At Slick Packages, we truly care about and pride ourselves on the
            quality of our services, and strive for 100% customer satisfaction.
            We have written the following to protect all parties from any
            misunderstandings that might otherwise lead to unnecessary actions.
            Please feel welcome to discuss any questions you may have with us
            directly. Thank you, we look forward to a great working
            relationship!
          </p>
        </div>
        <div className="termsText">
          <p className="bold caption">DEFINITIONS</p>
          <p>
            In this document the following words shall have the following
            meanings:
            <br />
            “Buyer” means the organization or person who buys Goods
            <br />
            “Goods” means the articles and services to be supplied to the Buyer
            by the Seller;
            <br />
            “Intellectual Property Rights” means all registered and unregistered
            designs, know-how and all other forms of intellectual property
            wherever in the world enforceable;
            <br />
            “Seller” means Slick Packages LLC (DBA Slick Packages)
          </p>
        </div>
        <div className="termsText">
          <p className="bold caption">GENERAL</p>
          <p>
            These Terms and Conditions shall apply to sales and/or exchange of
            Goods by the Seller to the Buyer to the exclusion of all other terms
            and conditions referred to, offered or relied on by the Buyer
            whether in negotiation or at any stage in the dealings between the
            parties, including any standard or printed terms tendered by the
            Buyer, unless the Buyer specifically states in writing, separately
            from such terms, that it wishes such terms to apply and this has
            been acknowledged by the Seller in writing.
            <br />
            Any variation to these Terms of Service (including any special terms
            of service agreed between the parties) shall be inapplicable unless
            agreed in writing by the Seller.
            <br />
            By creating a login account with the Seller or entering into a sale
            with the Seller is an expressed agreement of all terms of service on
            this page now and in the future, and in any relevant proposals sent
            by the Seller, as deemed relevant solely by the Seller.
            <br />
          </p>
        </div>
        <div className="termsText">
          <p className="bold caption">PRICE AND PAYMENT</p>
          <p>
            The price shall be the Recommended Retail Price less any agreed
            discount, unless otherwise agreed in writing between the parties.
            The price is exclusive of VAT or any other applicable costs.
            <br />
            Terms of payment will be offered and explained by the Seller through
            PayPal, Stripe, Authorize.net and/or check. The offer of credit or
            partial payment via PayPal or otherwise will be at the sole
            discretion of the Seller.
            <br />
            If payment of the price or any part thereof is not made by the due
            date as determined by the Seller, the Seller shall be entitled to:
            require payment in advance of delivery in relation to any Goods not
            previously delivered; refuse to make delivery of any undelivered
            Goods without incurring any liability whatever to the Buyer for
            non-delivery or any delay in delivery;
            <br />
          </p>
        </div>
        <div className="termsText">
          <p className="bold caption">DESCRIPTION</p>
          <p>
            Any description given or applied to the Goods is given by way of
            identification only and the use of such description shall not
            constitute a sale by description. For the avoidance of doubt, the
            Buyer hereby affirms that it does not in any way rely on any
            description when entering into the contract.
          </p>
        </div>
        <div className="termsText">
          <p className="bold caption">SAMPLE</p>
          <p>
            Where a sample of the Goods is shown to, shipped to and/or inspected
            by the Buyer, the parties hereto accept that such a sample is
            representative in nature and the bulk of the order may differ as a
            result of the creative and/or shipping process. Samples and
            prototypes are not to be considered final products, and the Buyer
            acknowledges that unfinished products do not always meet the full
            quality assurance standards of final deliveries.
          </p>
        </div>
        <div className="termsText">
          <p className="bold caption">
            DELIVERY, CANCELLATION AND REFUND POLICY
          </p>
          <p>
            Unless otherwise agreed in writing, delivery of the Goods shall take
            place on, or as close as possible to the date advertised by the
            Seller.
            <br />
            No revisions will be required, although will be permissible and
            potentially advertised and provided at the Seller’s sole discretion.
            <br />
            All sales made by Seller are final. Should the Buyer choose to
            cancel their order after the purchase has been submitted and
            approved by PayPal (and/or other means to purchase goods), the
            Seller is no longer obligated to refund any money for purchases
            <br />
            During all stages of interaction with the Seller through live chat,
            email, phone or otherwise, the Seller reserves the sole and full
            right to publicly published and advertise all customer reviews,
            photos and videos of final products for use in advertising, emails,
            website, social media or otherwise, and all other data the Seller
            has been provided or has access to during interaction with leads and
            customers.
            <br />
            All form submissions received through the Seller website and other
            communication serve as an explicit request to be contacted by the
            Seller and opt into all Seller communications that are deemed
            relevant by the Seller.
            <br />
            All forms of interaction with the Seller, including but not limited
            to emails, live chat, phone calls, payment and otherwise, serve as
            an explicit acceptance of all statements in these terms of service.
            The Seller is permitted to publicly publish and promote all data at
            zero charge, fee, penalty or liability from any other parties
            involved, without requesting or receiving written or formal
            permission.
            <br />
            The Seller is not required to provide any refunds to the Buyer at
            any time during any project, as all transactions are non-refundable,
            unless decidedly otherwise at the Seller’s sole discretion. While a
            standard refund of 50% of the payment price will typically be
            permitted by the Seller, all refunds will be approved or denied at
            sole discretion of the Seller. No refunds are mandatory. In the
            event of a full refund request, the Seller may refund the amount
            requested less all expenses incurred during the project, at the
            Seller’s sole discretion. This supersedes any other agreements made
            by the Seller elsewhere.
            <br />
          </p>
        </div>

        <div className="termsText">
          <p className="bold caption">RISK</p>
          <p>
            Risk in the Goods shall pass to the Buyer upon receipt of the goods.
            Where the Buyer chooses to collect the Goods itself, risk will pass
            when the Goods are entrusted to it or set aside for its collection,
            whichever happens first. Regarding monthly services, the Buyer
            accepts full liability for the change in ranking and penalty related
            to their site in all instances. While the Seller accepts
            responsibility to carry out desired results, there are no guarantees
            for results offered, promoted, or shown. All rankings, results, and
            other items shown to Buyer are not guarantees of success, but
            estimates, and should not be seen as average or typical. Any and all
            claims made by Seller are for educational and informational purposes
            only. There is no assurance that prior and all successes shown, or
            past results, can be used as an indication of future results. The
            Seller agrees that the Buyer is not liable for any success or
            failure of the Buyer’s business decisions related to any information
            provided by the Buyer. Upon delinquent payment, the Seller reserves
            the full discretion to take any action necessary to obtain remaining
            balance, including but not limited to, to lock, privatize, or
            restricting administrative access to products in question, as well
            as other instances as the Seller’s sole discretion. In the event of
            delinquent or incomplete payment by the Buyer, the Buyer will be
            required to pay the Seller a minimum of $500,000, or a mutually
            agreed upon amount between Buyer and Seller. In the event of
            incomplete payment by the Buyer, violation of terms of service, or
            any other reason necessary, litigation can and will be pursued at
            the Seller’s sole discretion, with all legal fees incurred in
            seeking recovery to be reimbursed in full by the Buyer.
          </p>
        </div>

        <div className="termsText">
          <p className="bold caption">TITLE</p>
          <p>
            Title in the Goods shall not pass to the Buyer until the Seller has
            been paid in full for the Goods.
          </p>
        </div>
        <div className="termsText">
          <p className="bold caption">LIMITATION OF LIABILITY</p>
          <p>
            The Seller shall not be liable for any all loss or damage suffered
            by the Buyer in excess of the contract price.
            <br />
            In all events of copyright infringement, cease and desist letters
            and all other legal events pursued against the Seller, there will be
            a maximum liability payment of $500 to be paid by the Seller only if
            arbitration rules in favor opposing the Seller. In all events of
            litigation brought forth against the Seller, the Prosecution agrees
            to pay the Seller’s legal fees and costs incurred during litigation,
            regardless of the outcome of litigation.
            <br />
            Nothing contained in these Terms and Conditions shall be construed
            so as to limit or exclude the liability of the Seller for death or
            personal injury as a result of the Seller’s negligence or that of
            its employees or agents.
          </p>
        </div>
        <div className="termsText">
          <p className="bold caption">INTELLECTUAL PROPERTY RIGHTS</p>
          <p>
            All Intellectual Property Rights produced from or arising as a
            result of the performance of this Agreement shall, unless explicitly
            written between both Buyer and Seller, become the absolute property
            of the Buyer and/or end user of Goods. The Seller shall do all that
            is reasonably necessary to ensure that such rights are executed by
            using the appropriate instruments or the making of agreements with
            third parties. Furthermore, any content or goods provided by Seller
            is not necessarily intended to represent any views or opinions by
            the Seller. The Seller does not claim any level of accuracy,
            legitimacy of statements, or make any other content or goods claims,
            as these will all reside with the Buyer.
          </p>
        </div>
        <div className="termsText">
          <p className="bold caption">FORCE MAJEURE</p>
          <p>
            The Seller shall not be liable for any delay or failure to perform
            any of its obligations if the delay or failure results from events
            or circumstances outside its reasonable control, including but not
            limited to acts of God, strikes, lock outs, shipping delays,
            accidents, war, fire, breakdown of plant or machinery or shortage or
            unavailability of raw materials from a natural source of supply, and
            the Seller shall be entitled to a reasonable extension of its
            obligations. If the delay persists for such time as the Seller
            considers unreasonable, it may, without liability on its part,
            terminate the contract.
          </p>
        </div>
        <div className="termsText">
          <p className="bold caption">RELATIONSHIP OF PARTIES</p>
          <p>
            Nothing contained in these Terms and Conditions shall be construed
            as establishing or implying any partnership or joint venture between
            the parties and nothing in these Terms and Conditions shall be
            deemed to construe either of the parties as the agent of the other.
          </p>
        </div>
        <div className="termsText">
          <p className="bold caption">ASSIGNMENT AND SUB-CONTRACTING</p>
          <p>
            The contract between the Buyer and Seller for the sale of Goods
            shall not be assigned or transferred, nor the performance of any
            obligation sub-contracted, in either case by the Buyer, without the
            prior written consent of the Seller. The Seller may assign, transfer
            or sub-contract any obligations as deemed necessary by the Seller.
          </p>
        </div>
        <div className="termsText">
          <p className="bold caption">WAIVER</p>
          <p>
            The failure by either party to enforce at any time or for any period
            any one or more of the Terms and Conditions herein shall not be a
            waiver of them or of the right at any time subsequently to enforce
            all Terms and Conditions of this Agreement.
          </p>
        </div>
        <div className="termsText">
          <p className="bold caption">SEVERABILITY</p>
          <p>
            If any term or provision of these Terms and Conditions is held
            invalid, illegal or unenforceable for any reason by any court of
            competent jurisdiction such provision shall be severed and the
            remainder of the provisions hereof shall continue in full force and
            effect as if these Terms and Conditions had been agreed with the
            invalid, illegal or unenforceable provision eliminated.
          </p>
        </div>
        <div className="termsText">
          <p className="bold caption">GOVERNING LAW AND JURISDICTION</p>
          <p>
            Any legal proceedings, whether initiated by the Seller or not, will
            be heard, reviewed and pursued in the county and state of the
            Seller’s sole discretion. If not stated otherwise, this will be
            Florida, United States. This Agreement shall be governed by and
            construed in accordance with the laws of the United States and the
            parties hereby submit to the exclusive jurisdiction of the United
            States courts.
          </p>
        </div>
      </div>
      <Social />
    </>
  );
}
