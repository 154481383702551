import React, { useState, useEffect, useRef } from "react";

/* ========== Image Imports ========== */
const DropdownOpen = require("../images/dropdown_open.svg");
const DropdownClose = require("../images/dropdown_close.svg");
const DropdownDarkOpen = require("../images/dropdown_dark_open.svg");
const DropdownDarkClose = require("../images/dropdown_dark_close.svg");
import ContactIcon from "../images/ContactIcon";
import TnCIcon from "../images/TnCIcon";
import WhyUsIcon from "../images/WhyUsIcon";
import AboutIcon from "../images/AboutIcon";
import SearchIcon from "../images/SearchIcon";
import Logo from "../images/Logo";

/* ========== Component Imports ========== */
import QuoteModal from "../components/QuoteModal/QuoteModal";
import SearchBar from "../components/Search/Search";

/* ========== Data Imports ========== */
// const categories = require("../data/categories");
import categories from "../data/categories";
const categoriesByDesign = categories.design;
const categoriesByMaterial = categories.material;
const categoriesByIndustry = categories.industry;

import ScrollToTop from "./ScrollToTop";

import "./styles.css";

import { BrowserRouter as Router, Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Loading from "../components/Loading/Loading";
import Routes from "./Routes";

export default function NavigationBar() {
  const navigationRef = useRef();
  const designSearchBarRef = useRef();
  const materialSearchBarRef = useRef();
  const industrySearchBarRef = useRef();
  const [isDesignNavOpen, setDesignNavOpen] = useState(false);
  const [isMaterialNavOpen, setMaterialNavOpen] = useState(false);
  const [isIndustryNavOpen, setIndustryNavOpen] = useState(false);
  const [isAboutNavOpen, setAboutNavOpen] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const [designSearchBarValue, setDesignSearchBarValue] = useState("");
  const [industrySearchBarValue, setIndustrySearchBarValue] = useState("");
  const [materialSearchBarValue, setMaterialSearchBarValue] = useState("");
  const [mainSearchBarValue, setMainSearchBarValue] = useState("");

  useEffect(() => {
    const listener = (event) => {
      if (
        !navigationRef.current ||
        navigationRef.current.contains(event.target)
      ) {
        return;
      }
      closeNavigation();
      setIsSearchBarOpen(false);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [navigationRef]);

  function toggleMobileNavigation() {
    setIsFirstOpen(false);
    isMobileNavOpen ? setIsMobileNavOpen(false) : setIsMobileNavOpen(true);
  }

  function closeNavigation() {
    setIsMobileNavOpen(false);
    setMaterialNavOpen(false);
    setIndustryNavOpen(false);
    setAboutNavOpen(false);
    setDesignNavOpen(false);
  }

  function clearSearchBars() {
    setIndustrySearchBarValue("");
    setDesignSearchBarValue("");
    setMaterialSearchBarValue("");
    setMainSearchBarValue("");
  }

  function toggleDesignNav() {
    setMaterialNavOpen(false);
    setIndustryNavOpen(false);
    setAboutNavOpen(false);
    setDesignNavOpen(isDesignNavOpen ? false : true);
  }

  function toggleIndustryNav() {
    setMaterialNavOpen(false);
    setIndustryNavOpen(isIndustryNavOpen ? false : true);
    setAboutNavOpen(false);
    setDesignNavOpen(false);
  }

  function toggleMaterialNav() {
    setMaterialNavOpen(isMaterialNavOpen ? false : true);
    setIndustryNavOpen(false);
    setAboutNavOpen(false);
    setDesignNavOpen(false);
  }

  function toggleAboutNav() {
    setMaterialNavOpen(false);
    setIndustryNavOpen(false);
    setAboutNavOpen(isAboutNavOpen ? false : true);
    setDesignNavOpen(false);
  }

  function navigate(ref) {
    ref.current.click();
  }

  return (
    <>
      <Loading />
      <Router>
        <QuoteModal />
        <main>
          <nav ref={navigationRef} className="navigation">
            <div className="hamburger">
              <div
                className={`bars ${
                  isFirstOpen
                    ? ""
                    : isMobileNavOpen
                    ? "animateForward"
                    : "animateBackward"
                } noSelect`}
                onClick={toggleMobileNavigation}
                onKeyDown={toggleMobileNavigation}
                role="button"
                tabIndex="0"
                aria-label="Menu Bar"
              >
                <span className="bar topBar"></span>
                <span className="bar middleBar"></span>
                <span className="bar bottomBar"></span>
              </div>
            </div>
            <div className="logoContainer noSelect">
              <Link
                onClick={() => {
                  closeNavigation();
                  clearSearchBars();
                }}
                to="/"
              >
                <Logo className="logoImage" color="#333" />
              </Link>
            </div>
            <div className={`menu ${isMobileNavOpen ? "show" : "showDesktop"}`}>
              <ul className="mainMenu">
                <li className="item noSelect">
                  <div
                    className="title noSelect"
                    onClick={toggleDesignNav}
                    onKeyPress={toggleDesignNav}
                    onMouseEnter={toggleDesignNav}
                    onFocus={toggleDesignNav}
                    role="button"
                    tabIndex="0"
                  >
                    <p
                      className={`itemName ${
                        isDesignNavOpen ? "itemNameSelected" : ""
                      }`}
                    >
                      By Design
                    </p>
                    <picture>
                      <source
                        media="(max-width: 768px)"
                        srcSet={
                          isDesignNavOpen ? DropdownDarkOpen : DropdownDarkClose
                        }
                      />
                      <source
                        media="(min-width: 769px)"
                        srcSet={isDesignNavOpen ? DropdownOpen : DropdownClose}
                      />
                      <img
                        src={isDesignNavOpen ? DropdownOpen : DropdownClose}
                        alt="dropdown_arrow"
                      />
                    </picture>
                  </div>

                  <div
                    className={`secondaryNav ${
                      isDesignNavOpen ? "secondaryNavOpen" : "secondaryNavClose"
                    }`}
                    onMouseLeave={closeNavigation}
                    onBlur={closeNavigation}
                  >
                    <ul>
                      {categoriesByDesign.map((category) => (
                        <li className="secondaryItem" key={category.title}>
                          <Link
                            to={`/byDesign/${category.id}`}
                            onClick={() => {
                              closeNavigation();
                              clearSearchBars();
                            }}
                          >
                            <div className="secondaryItemTile">
                              <picture>
                                <source
                                  media="(max-width: 768px)"
                                  srcSet={category.picture_mobile}
                                />
                                <source
                                  media="(min-width: 769px)"
                                  srcSet={category.picture_desktop}
                                />
                                <img
                                  src={category.picture_desktop}
                                  alt="pick_a_material_item"
                                />
                              </picture>
                              <p className="secondaryItemName">
                                {category.title}
                              </p>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <div className="secondaryNavSearch">
                      <p className="helpText">
                        Didn&apos;t find what you were looking for?
                      </p>
                      <div className="secondarySearch">
                        <div className="searchField">
                          <SearchIcon />
                          <input
                            type="text"
                            className="searchFieldInput"
                            value={designSearchBarValue}
                            onMouseLeave={(ev) => ev.stopPropagation()}
                            onBlur={(ev) => ev.stopPropagation()}
                            onChange={(event) =>
                              setDesignSearchBarValue(event.target.value)
                            }
                            onKeyDown={(e) => {
                              if (e.key == "Enter") {
                                if (designSearchBarValue) {
                                  navigate(designSearchBarRef);
                                }
                              }
                            }}
                          />
                        </div>
                        <Link
                          ref={designSearchBarRef}
                          to={`/search/${designSearchBarValue}`}
                          className="secondaryContactLink searchButton"
                          onClick={() => {
                            clearSearchBars();
                            closeNavigation();
                          }}
                        >
                          <button className="searchButton">Search</button>
                        </Link>
                      </div>
                      <Link
                        to="/contact"
                        className="secondaryContactLink"
                        onClick={() => {
                          clearSearchBars();
                          closeNavigation();
                        }}
                      >
                        or Contact Us
                      </Link>
                    </div>
                  </div>
                </li>
                <li className="item noSelect">
                  <div
                    className="title noSelect"
                    onClick={toggleMaterialNav}
                    onKeyPress={toggleMaterialNav}
                    onMouseEnter={toggleMaterialNav}
                    onFocus={toggleMaterialNav}
                    role="button"
                    tabIndex="0"
                  >
                    <p
                      className={`itemName ${
                        isMaterialNavOpen ? "itemNameSelected" : ""
                      }`}
                    >
                      By Material
                    </p>
                    <picture>
                      <source
                        media="(max-width: 768px)"
                        srcSet={
                          isMaterialNavOpen
                            ? DropdownDarkOpen
                            : DropdownDarkClose
                        }
                      />
                      <source
                        media="(min-width: 769px)"
                        srcSet={
                          isMaterialNavOpen ? DropdownOpen : DropdownClose
                        }
                      />
                      <img
                        src={isMaterialNavOpen ? DropdownOpen : DropdownClose}
                        alt="dropdown_arrow"
                      />
                    </picture>
                  </div>

                  <div
                    className={`secondaryNav ${
                      isMaterialNavOpen
                        ? "secondaryNavOpen"
                        : "secondaryNavClose"
                    }`}
                    onMouseLeave={closeNavigation}
                    onBlur={closeNavigation}
                  >
                    <ul>
                      {categoriesByMaterial.map((category) => (
                        <li className="secondaryItem" key={category.title}>
                          <Link
                            onClick={() => {
                              closeNavigation();
                              clearSearchBars();
                            }}
                            to={`/byMaterial/${category.id}`}
                          >
                            <div className="secondaryItemTile">
                              <picture>
                                <source
                                  media="(max-width: 768px)"
                                  srcSet={category.picture_mobile}
                                />
                                <source
                                  media="(min-width: 769px)"
                                  srcSet={category.picture_desktop}
                                />
                                <img
                                  src={category.picture_desktop}
                                  alt="pick_a_material_item"
                                />
                              </picture>
                              <p className="secondaryItemName">
                                {category.title}
                              </p>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <div className="secondaryNavSearch">
                      <p className="helpText">
                        Didn&apos;t find what you were looking for?
                      </p>
                      <div className="secondarySearch">
                        <div className="searchField">
                          <SearchIcon />
                          <input
                            type="text"
                            className="searchFieldInput"
                            value={materialSearchBarValue}
                            onMouseLeave={(ev) => ev.stopPropagation()}
                            onBlur={(ev) => ev.stopPropagation()}
                            onChange={(event) =>
                              setMaterialSearchBarValue(event.target.value)
                            }
                            onKeyDown={(e) => {
                              if (e.key == "Enter") {
                                if (materialSearchBarValue) {
                                  navigate(materialSearchBarRef);
                                }
                              }
                            }}
                          />
                        </div>
                        <Link
                          ref={materialSearchBarRef}
                          to={`/search/${materialSearchBarValue}`}
                          className="secondaryContactLink"
                          onClick={() => {
                            closeNavigation();
                            clearSearchBars();
                          }}
                        >
                          <button className="searchButton">Search</button>
                        </Link>
                      </div>
                      <Link
                        onClick={() => {
                          clearSearchBars();
                          closeNavigation();
                        }}
                        to="/contact"
                        className="secondaryContactLink"
                      >
                        or Contact Us
                      </Link>
                    </div>
                  </div>
                </li>
                <li className="item noSelect">
                  <div
                    className="title noSelect"
                    onClick={toggleIndustryNav}
                    onKeyPress={toggleIndustryNav}
                    onMouseEnter={toggleIndustryNav}
                    onFocus={toggleIndustryNav}
                    role="button"
                    tabIndex="0"
                  >
                    <p
                      className={`itemName ${
                        isIndustryNavOpen ? "itemNameSelected" : ""
                      }`}
                    >
                      By Industry
                    </p>
                    <picture>
                      <source
                        media="(max-width: 768px)"
                        srcSet={
                          isIndustryNavOpen
                            ? DropdownDarkOpen
                            : DropdownDarkClose
                        }
                      />
                      <source
                        media="(min-width: 769px)"
                        srcSet={
                          isIndustryNavOpen ? DropdownOpen : DropdownClose
                        }
                      />
                      <img
                        src={isIndustryNavOpen ? DropdownOpen : DropdownClose}
                        alt="dropdown_arrow"
                      />
                    </picture>
                  </div>

                  <div
                    className={`secondaryNav ${
                      isIndustryNavOpen
                        ? "secondaryNavOpen"
                        : "secondaryNavClose"
                    }`}
                    onMouseLeave={closeNavigation}
                    onBlur={closeNavigation}
                  >
                    <ul>
                      {categoriesByIndustry.map((category) => (
                        <li className="secondaryItem" key={category.title}>
                          <Link
                            onClick={() => {
                              closeNavigation();
                              clearSearchBars();
                            }}
                            to={`/byIndustry/${category.id}`}
                          >
                            <div className="secondaryItemTile">
                              <picture>
                                <source
                                  media="(max-width: 768px)"
                                  srcSet={category.picture_mobile}
                                />
                                <source
                                  media="(min-width: 769px)"
                                  srcSet={category.picture_desktop}
                                />
                                <img
                                  src={category.picture_desktop}
                                  alt="pick_a_material_item"
                                />
                              </picture>
                              <p className="secondaryItemName">
                                {category.title}
                              </p>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <div className="secondaryNavSearch">
                      <p className="helpText">
                        Didn&apos;t find what you were looking for?
                      </p>
                      <div className="secondarySearch">
                        <div className="searchField">
                          <SearchIcon />
                          <input
                            type="text"
                            className="searchFieldInput"
                            value={industrySearchBarValue}
                            onMouseLeave={(ev) => ev.stopPropagation()}
                            onBlur={(ev) => ev.stopPropagation()}
                            onChange={(event) =>
                              setIndustrySearchBarValue(event.target.value)
                            }
                            onKeyDown={(e) => {
                              if (e.key == "Enter") {
                                if (industrySearchBarValue) {
                                  navigate(industrySearchBarRef);
                                }
                              }
                            }}
                          />
                        </div>
                        <Link
                          ref={industrySearchBarRef}
                          onClick={() => {
                            closeNavigation();
                            clearSearchBars();
                          }}
                          to={`/search/${industrySearchBarValue}`}
                          className="secondaryContactLink"
                        >
                          <button className="searchButton">Search</button>
                        </Link>
                      </div>
                      <Link
                        to="/contact"
                        className="secondaryContactLink"
                        onClick={() => {
                          clearSearchBars();
                          closeNavigation();
                        }}
                      >
                        or Contact Us
                      </Link>
                    </div>
                  </div>
                </li>
                <li className="item about noSelect">
                  <div
                    className="title noSelect"
                    onClick={toggleAboutNav}
                    onKeyPress={toggleAboutNav}
                    onMouseEnter={toggleAboutNav}
                    onFocus={toggleAboutNav}
                    role="button"
                    tabIndex="0"
                  >
                    <p
                      className={`itemName ${
                        isAboutNavOpen ? "itemNameSelected" : ""
                      }`}
                    >
                      About
                    </p>
                    <picture>
                      <source
                        media="(max-width: 768px)"
                        srcSet={
                          isAboutNavOpen ? DropdownDarkOpen : DropdownDarkClose
                        }
                      />
                      <source
                        media="(min-width: 769px)"
                        srcSet={isAboutNavOpen ? DropdownOpen : DropdownClose}
                      />
                      <img
                        src={isAboutNavOpen ? DropdownOpen : DropdownClose}
                        alt="dropdown_arrow"
                      />
                    </picture>
                  </div>
                  <div
                    className={`secondaryNav ${
                      isAboutNavOpen ? "secondaryNavOpen" : "secondaryNavClose"
                    } secondaryNavList`}
                    onMouseLeave={closeNavigation}
                    onBlur={closeNavigation}
                  >
                    <ul>
                      <li className="secondaryListItem">
                        <Link
                          onClick={() => {
                            closeNavigation();
                            clearSearchBars();
                          }}
                          to="/why-choose-us"
                        >
                          <WhyUsIcon className="aboutSVG" />
                          <p className="listItemName">Why Choose Us?</p>
                        </Link>
                      </li>
                      <li className="secondaryListItem">
                        <Link
                          onClick={() => {
                            closeNavigation();
                            clearSearchBars();
                          }}
                          to="/about"
                        >
                          <AboutIcon className="aboutSVG" />
                          <p className="listItemName">About Us</p>
                        </Link>
                      </li>
                      <li className="secondaryListItem">
                        <Link
                          onClick={() => {
                            closeNavigation();
                            clearSearchBars();
                          }}
                          to="/contact"
                        >
                          <ContactIcon className="aboutSVG" />
                          <p className="listItemName">Contact Us</p>
                        </Link>
                      </li>
                      <li className="secondaryListItem">
                        <Link
                          onClick={() => {
                            closeNavigation();
                            clearSearchBars();
                          }}
                          to="/terms"
                        >
                          <TnCIcon className="aboutSVG" />
                          <p className="listItemName">Our Terms of Service</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="searchContainer"
              role="button"
              tabIndex="-1"
              onClick={() => {
                closeNavigation();
                setIsSearchBarOpen(true);
              }}
              onKeyPress={() => {
                closeNavigation();
                setIsSearchBarOpen(true);
              }}
            >
              <SearchIcon />
              <SearchBar
                isOpen={isSearchBarOpen}
                text={mainSearchBarValue}
                onChange={(text) => setMainSearchBarValue(text)}
                linkOnGoPressed={`/search/${mainSearchBarValue}`}
                onClick={(event) => {
                  event.stopPropagation();
                  setIsSearchBarOpen(false);
                  clearSearchBars();
                }}
              />
            </div>
          </nav>
          <ScrollToTop />
          <Routes />
        </main>

        <Footer />
      </Router>
    </>
  );
}
