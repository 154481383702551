import React from "react";

function ChatIcon(props) {
  const fill = props.isdark === "true" ? "#ffffff" : "#4b3ed2";
  const stroke = props.isdark === "true" ? "#4b3ed2" : "#ffffff";
  return (
    <svg
      width={66}
      height={66}
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#prefix__filter0_d)">
        <path
          d="M33 57c14.912 0 27-12.088 27-27S47.912 3 33 3 6 15.088 6 30s12.088 27 27 27z"
          fill={fill}
        />
      </g>
      <path
        d="M43.615 20.58A17.092 17.092 0 0033 17.08a17.032 17.032 0 00-10.614 3.5 10.85 10.85 0 00-4.422 8.501 10.871 10.871 0 004.422 8.5c.058.044.117.087.175.138l.022.015a.581.581 0 01.226.488l-.211 5.485 6.8-2.87a.564.564 0 01.328-.036c1.082.19 2.179.285 3.278.284 3.83.06 7.57-1.172 10.614-3.5a10.838 10.838 0 004.422-8.5 10.852 10.852 0 00-4.426-8.505zm-18.55 11.351a2.848 2.848 0 11-.008-5.697 2.848 2.848 0 01.008 5.697zm7.94 0a2.848 2.848 0 111.087-.215 2.847 2.847 0 01-1.091.215h.004zm7.94 0a2.848 2.848 0 111.087-.215 2.847 2.847 0 01-1.091.215h.004z"
        fill={stroke}
      />
      <defs>
        <filter
          id="prefix__filter0_d"
          x={0}
          y={0}
          width={66}
          height={66}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default ChatIcon;
