import React from "react";

function SearchGoIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12.135}
      height={11.554}
      viewBox="0 0 12.135 11.554"
      {...props}
    >
      <g data-name="Group 2178">
        <g
          data-name="Icon feather-arrow-left"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path data-name="Path 1151" d="M.75 5.777h10.635" />
          <path data-name="Path 1152" d="M6.669 1.06l4.716 4.717-4.716 4.716" />
        </g>
      </g>
    </svg>
  );
}

export default SearchGoIcon;
