import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";

import { UIContext, FooterTypes } from "../../context/UIContext";

// const CustomerImage = require("../../images/customer_logo.png");
// const CustomerImageGray = require("../../images/customer_logo_gray.png");
// const CurvedBG = require("../../images/bg_curved.png");
import CustomQuote from "../../components/CustomQuote/CustomQuote";
import FeaturedBoxes from "../../components/FeaturedBoxes/FeaturedBoxes";

import "./styles.css";
import CompanyAttributes from "../../components/CompanyAttributes/CompanyAttributes";
// import ReviewCarousel from "../../components/ReviewCarousel/ReviewCarousel";
import SplitScroll from "../../components/SplitScroll/SplitScroll";

export default function Home() {
  const { setFooterType, closeLoader } = useContext(UIContext);

  useEffect(() => {
    setFooterType(FooterTypes.KIT);
    closeLoader();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Custom Box Packaging | Custom Boxes Wholesale - Slick Packages
        </title>
        <meta
          property="og:title"
          content="Custom Box Packaging | Custom Boxes Wholesale - Slick Packages"
        />
        <meta
          charSet="utf-8"
          content="Are you looking for Custom Box Packaging and Custom Boxes Wholesale? Contact us to get the best Custom Box Packaging at wholesale rate."
        />
        <meta
          property="og:description"
          content="Are you looking for Custom Box Packaging and Custom Boxes Wholesale? Contact us to get the best Custom Box Packaging at wholesale rate."
        />
        <meta
          name="keywords"
          content="Custom Box Packaging, Custom Boxes Wholesale"
        />
        <link rel="canonical" href="https://slickpackages.com/" />
        <meta property="og:url" content="https://slickpackages.com/" />
      </Helmet>
      <div className="hero">
        <div className="textSection">
          <h2 className="h1">
            Level up your product in minutes,
            <br />
            stand out forever!
          </h2>
          <p>
            We help businesses build their brand identity with our premium
            custom packaging solutions.
          </p>
          {/* <p className="bold">WANT MORE? TELL US.</p> */}
        </div>
      </div>
      {/* <div className="textSection customersWrapper">
        <img
          className="curvedBackground"
          src={CurvedBG}
          alt="customers_background"
        />
        <div className="customersContent">
          <h2 className="h1">WHAT OUR CUSTOMERS SAY</h2>
          <p className="blue bold">
            Our customers from around the world keep coming back to us!
          </p>
          <div className="customers">
            <img
              className="customerImage"
              src={CustomerImageGray}
              onMouseOver={(e) => (e.currentTarget.src = CustomerImage)}
              onMouseOut={(e) => (e.currentTarget.src = CustomerImageGray)}
              onFocus={(e) => (e.currentTarget.src = CustomerImage)}
              onBlur={(e) => (e.currentTarget.src = CustomerImageGray)}
              alt="happy_customer"
            />
            <img
              className="customerImage"
              src={CustomerImageGray}
              onMouseOver={(e) => (e.currentTarget.src = CustomerImage)}
              onMouseOut={(e) => (e.currentTarget.src = CustomerImageGray)}
              onFocus={(e) => (e.currentTarget.src = CustomerImage)}
              onBlur={(e) => (e.currentTarget.src = CustomerImageGray)}
              alt="happy_customer"
            />
            <img
              className="customerImage"
              src={CustomerImageGray}
              onMouseOver={(e) => (e.currentTarget.src = CustomerImage)}
              onMouseOut={(e) => (e.currentTarget.src = CustomerImageGray)}
              onFocus={(e) => (e.currentTarget.src = CustomerImage)}
              onBlur={(e) => (e.currentTarget.src = CustomerImageGray)}
              alt="happy_customer"
            />
            <img
              className="customerImage"
              src={CustomerImageGray}
              onMouseOver={(e) => (e.currentTarget.src = CustomerImage)}
              onMouseOut={(e) => (e.currentTarget.src = CustomerImageGray)}
              onFocus={(e) => (e.currentTarget.src = CustomerImage)}
              onBlur={(e) => (e.currentTarget.src = CustomerImageGray)}
              alt="happy_customer"
            />
            <img
              className="customerImage"
              src={CustomerImageGray}
              onMouseOver={(e) => (e.currentTarget.src = CustomerImage)}
              onMouseOut={(e) => (e.currentTarget.src = CustomerImageGray)}
              onFocus={(e) => (e.currentTarget.src = CustomerImage)}
              onBlur={(e) => (e.currentTarget.src = CustomerImageGray)}
              alt="happy_customer"
            />
          </div>
        </div>
      </div> */}
      <CompanyAttributes />
      <SplitScroll
        title="Our Process"
        subtitle="IN A NUTSHELL"
        description="Our timeless process makes sure that you get whatever you want it wherever you want."
        step1={{
          title: "Pick a box",
          description:
            "Pick your desired box from our wide range of custom boxes or tell us your ideas and and desired specifications.",
        }}
        step2={{
          title: "Get it customized",
          description:
            "Work with our in-house dedicated designers to customize your boxes according to your product needs.",
        }}
        step3={{
          title: "Sit back and relax",
          description:
            "Get a prototype ready for your feedback within 7 business days. Get your complete order delivered within 15 business days.",
        }}
      />
      <CustomQuote />
      <FeaturedBoxes />
      {/* <ReviewCarousel /> */}
    </>
  );
}
