import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { UIContext, FooterTypes } from "../../context/UIContext";
import { useLocation, useHistory } from "react-router-dom";

import CustomQuote from "../../components/CustomQuote/CustomQuote";
import FeaturedBoxes from "../../components/FeaturedBoxes/FeaturedBoxes";
import BoxDetails from "../../components/BoxDetails/BoxDetails";
import CompanyAttributes from "../../components/CompanyAttributes/CompanyAttributes";

import {
  DesignCollection,
  IndustryCollection,
  MaterialCollection,
} from "../../data/firebase";

export default function Product() {
  const history = useHistory();
  const { setFooterType, closeLoader } = useContext(UIContext);
  const [isLoading, setIsLoading] = useState(true);
  const [box, setBox] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setFooterType(FooterTypes.SEARCH);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      closeLoader();
    }
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
    getBox();
  }, [location.pathname]);

  function getBox() {
    const path = location.pathname.split("/");
    let collection = null;
    if (path[1] === "byDesign") {
      // fetch design box
      collection = DesignCollection;
    } else if (path[1] === "byMaterial") {
      // fetch material box
      collection = MaterialCollection;
    } else if (path[1] === "byIndustry") {
      // fetch material box
      collection = IndustryCollection;
    }

    if (collection === IndustryCollection) {
      collection
        .doc(path[2])
        .collection("subCategories")
        .doc(path[3])
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) {
            history.push("/not-found");
          } else {
            setBox(snapshot.data());
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          history.push("/not-found");
        });
    } else {
      collection
        .doc(path[2])
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) {
            history.push("/not-found");
          } else {
            setBox(snapshot.data());
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          history.push("/not-found");
        });
    }
  }

  return isLoading ? (
    <div className="dummyProduct"></div>
  ) : (
    <>
      <Helmet>
        <title className="productTitle">{box.title}</title>
        <meta charSet="utf-8" content={box.meta} />
        <link rel="canonical" href={box.link} />
        <meta property="og:title" content={`${box.title}`} />
        <meta property="og:description" content={box.meta} />
        <meta property="og:url" content={box.link} />
      </Helmet>
      <BoxDetails box={box} />
      <CustomQuote />
      <CompanyAttributes />
      <FeaturedBoxes />
    </>
  );
}
