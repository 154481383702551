import React, { useContext } from "react";
import { UIContext } from "../../context/UIContext";
const SmallBox = require("../../images/small_box.png");

import "./styles.css";

export default function CustomQuote() {
  const { openQuoteModal } = useContext(UIContext);

  return (
    <>
      <div className="getCustomQuote">
        <img src={SmallBox} alt="Quote_Image" />
        <h1 className="h1 white">Want Top-Notch Custom Box Packaging?</h1>
        <p className="bold white centered">GET A CUSTOM QUOTE FOR FREE</p>
        <p className="white centered">
          Every order we receive has its own requirements. We can create a
          custom quote for yours within 2 business days.
        </p>
        <button onClick={() => openQuoteModal()}>Get Quote</button>
      </div>
    </>
  );
}
