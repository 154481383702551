import React from "react";

const TnCIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="31"
    viewBox="0 0 30 31"
    {...props}
  >
    <g id="Group_66" data-name="Group 66" transform="translate(0 0.094)">
      <ellipse
        id="Ellipse_19"
        data-name="Ellipse 19"
        cx="15"
        cy="15.5"
        rx="15"
        ry="15.5"
        transform="translate(0 -0.094)"
      />
      <path
        id="Subtraction_1"
        data-name="Subtraction 1"
        d="M10,18H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0h6a4,4,0,0,1,4,4V14A4.005,4.005,0,0,1,10,18ZM3.925,12.75a1.125,1.125,0,1,0,0,2.249h6.151a1.125,1.125,0,1,0,0-2.249Zm0-4.5a1.125,1.125,0,0,0,0,2.25h6.151a1.125,1.125,0,0,0,0-2.25Zm0-4.5a1.125,1.125,0,1,0,0,2.25h6.151a1.125,1.125,0,1,0,0-2.25Z"
        transform="translate(8 6.906)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default TnCIcon;
