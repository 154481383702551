import React, { useEffect, useContext } from "react";
import { UIContext, LoadingDirections } from "../context/UIContext";
import { initAuth } from "../data/firebase";

/* ========== Page Imports ========== */
import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import WhyUs from "../pages/WhyUs/WhyUs";
import Contact from "../pages/Contact/Contact";
import SubCategory from "../pages/SubCategory/SubCategory";
import Product from "../pages/Product/Product";
import Search from "../pages/Search/Search";
import TnC from "../pages/TnC/TnC";
import NotFound from "../pages/404/404";

import "./styles.css";

import { Route, Switch, useHistory } from "react-router-dom";
import RequestKit from "../pages/RequestKit/RequestKit";

export default function Routes() {
  const { loadLoader } = useContext(UIContext);
  const history = useHistory();

  useEffect(() => {
    initAuth();
  }, []);

  useEffect(() => {
    const unblock = history.block(({ pathname }) => {
      if (pathname !== location.pathname) {
        loadLoader(LoadingDirections.LEFT_IN_COMPACT);
        setTimeout(() => {
          unblock();
          history.push(pathname);
        }, 1500);
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [location.pathname]);

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/about" component={About} />
      <Route path="/contact" component={Contact} />
      <Route path="/why-choose-us" component={WhyUs} />
      <Route path="/terms" component={TnC} />
      <Route
        path="/byIndustry/:sub_category_name/:box_name"
        component={Product}
      />
      <Route path="/byIndustry/:sub_category_name/" component={SubCategory} />
      <Route path="/byDesign/:box_id" component={Product} />
      <Route path="/byMaterial/:box_id" component={Product} />
      <Route path="/search/" component={Search} />
      <Route path="/search/:query" component={Search} />
      <Route path="/get-sample-kit" component={RequestKit} />
      <Route path="/not-found" component={NotFound} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}
