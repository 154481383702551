import React from "react";
import { render } from "react-dom";
import AppRouter from "./router";
import { UIContextProvider } from "./context/UIContext";
import "./styles.css";

const App = () => {
  return (
    <UIContextProvider>
      <AppRouter />
    </UIContextProvider>
  );
};

render(React.createElement(App), document.getElementById("root"));
