import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";

const LeftArrow = require("../../images/slick_arrow_left.png");
const RightArrow = require("../../images/slick_arrow_right.png");

const SliderArrow = ({ classN, to, onClick, imgSrc }) => (
  <div
    role="button"
    tabIndex="-1"
    onKeyUp={onClick}
    onClick={onClick}
    className={`button button--text button--icon ${classN}`}
    aria-label={to}
  >
    <img className="icon" icon={to} src={imgSrc} width={10} height={15} alt="Arrow" />
  </div>
);

export default function BoxCarousel({ images }) {
  return (
    <div className="boxSliderWrapper">
      <Slider
        className="boxSlider"
        dots={true}
        infinite={true}
        adaptiveHeight={true}
        slidesToScroll={1}
        slidesToShow={1}
        prevArrow={<SliderArrow to="prev" imgSrc={LeftArrow} classN="prev" />}
        nextArrow={<SliderArrow to="next" imgSrc={RightArrow} classN="next" />}
      >
        {images.big.map((i, index) => {
          if (index === 0) {
            return (
              <div key={index.toString()}>
                <div className="productImageContainer">
                  <p className="dummy">p_i</p>
                  <img className="productImage" width={250} height={250} src={i} alt="Box" />
                </div>
              </div>
            );
          }
          return (
            <div key={index.toString()}>
              <div className="productImageContainer">
                <img className="productImage" src={i} alt="Box" />
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
