import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { UIContext, FooterTypes } from "../../context/UIContext";

import "./styles.css";

export default function NotFound() {
  const { setFooterType, closeLoader } = useContext(UIContext);

  useEffect(() => {
    setFooterType(FooterTypes.SEARCH);
    closeLoader();
  }, []);
  return (
    <>
      <Helmet>
        <title>Not Found | 404</title>
        <meta property="og:title" content="Not Found | 404" />
        <meta
          charSet="utf-8"
          content="The page you are looking for does not exist."
        />
        <meta
          property="og:description"
          content="The page you are looking for does not exist."
        />
        <link rel="canonical" href="https://slickpackages.com/not-found" />
        <meta property="og:url" content="https://slickpackages.com/not-found" />
      </Helmet>
      <div className="notFound">
        <p className="text centered">Ah oh! :( This page doesn’t exist.</p>
        <div className="image" />
        <p className="text centered gray">It’s deserted in here...</p>
      </div>
    </>
  );
}
