import React, { useLayoutEffect, useRef } from "react";
import SearchGoIcon from "../../images/SearchGoIcon";
import { useHistory } from "react-router-dom";

import "./styles.css";

export default function Search({
  isOpen,
  text,
  onChange,
  linkOnGoPressed,
  onClick,
}) {
  const history = useHistory();
  const inputRef = useRef();
  useLayoutEffect(() => {
    inputRef.current.focus();
  });
  return (
    <>
      <div className={`searchBarContainer ${!isOpen ? "hideSearchBar" : ""}`}>
        <input
          ref={inputRef}
          type="text"
          value={text}
          onChange={(event) => onChange(event.target.value)}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              if (text) {
                onClick(e);
                history.push(linkOnGoPressed);
              }
            }
          }}
        />
        <span
          className="searchBarGoButton"
          role="button"
          tabIndex="-1"
          onKeyPress={(event) => {
            if (text) {
              onClick(event);
              history.push(linkOnGoPressed);
            }
          }}
          onClick={(event) => {
            if (text) {
              onClick(event);
              history.push(linkOnGoPressed);
            }
          }}
        >
          <SearchGoIcon />
        </span>
      </div>
    </>
  );
}
