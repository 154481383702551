import React from "react";

const AboutIcon = (props) => (
  <svg
    id="Group_64"
    data-name="Group 64"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="31"
    viewBox="0 0 30 31"
    {...props}
  >
    <ellipse
      id="Ellipse_19"
      data-name="Ellipse 19"
      cx="15"
      cy="15.5"
      rx="15"
      ry="15.5"
    />
    <g id="Group_63" data-name="Group 63" transform="translate(13 6)">
      <rect
        id="Rectangle_103"
        data-name="Rectangle 103"
        width="4"
        height="13"
        rx="2"
        transform="translate(0 7)"
        fill="#fff"
      />
      <ellipse
        id="Ellipse_20"
        data-name="Ellipse 20"
        cx="2"
        cy="2.5"
        rx="2"
        ry="2.5"
        fill="#fff"
      />
    </g>
  </svg>
);

export default AboutIcon;
