import React from "react";

import Facebook from "../../images/Facebook";
import Twitter from "../../images/Twitter";
import Instagram from "../../images/Instagram";

import "./styles.css";

export default function Social() {
  return (
    <>
      <div className="socialContainer">
        <h2 className="h1">Connect With Us</h2>
        <div className="socialIcons">
          <a href="https://facebook.com">
            <Facebook className="facebook" />
          </a>
          <a href="https://instagram.com">
            <Instagram className="instagram" />
          </a>
          <a href="https://twitter.com">
            <Twitter className="twitter" />
          </a>
        </div>
      </div>
    </>
  );
}
