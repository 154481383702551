import React from "react";

function Facebook(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={35.631}
      height={61.512}
      viewBox="0 0 35.631 61.512"
      {...props}
    >
      <path
        d="M33.631 2H25a14.378 14.378 0 00-14.373 14.378V25H2v11.5h8.627v23h11.5v-23h8.627L33.631 25h-11.5v-8.622A2.876 2.876 0 0125 13.5h8.627z"
        className={props.className}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
      />
    </svg>
  );
}

export default Facebook;
