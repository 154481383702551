import React, { useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { FeaturedCollection } from "../../data/firebase";
import "./styles.css";

export default function FeaturedBoxes() {
  const [featuredBoxes, setFeaturedBoxes] = useState([]);

  useEffect(() => {
    getFeaturedBoxes();
  }, []);

  function getFeaturedBoxes() {
    FeaturedCollection.get()
      .then((snapshot) => {
        const featured = snapshot.docs.map((d) => ({
          id: d.id,
          name: d.data().name,
          description: d.data().description,
          link: d.data().link,
          picture: d.data().picture,
        }));
        setFeaturedBoxes(featured);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div className="featuredBoxes">
      <h2 className="h1">Featured Products</h2>
      <ScrollContainer className="featuredBoxesContent" hideScrollbars={false}>
        {featuredBoxes.map((box) => {
          return (
            <a href={box.link} className="featuredBox" key={box.id}>
              <img src={box.picture} alt="Featured Box" />
              <h2 className="h2">{box.name}</h2>
              <p>{box.description}</p>
            </a>
          );
        })}
        <div className="marginRight" />
      </ScrollContainer>
    </div>
  );
}
