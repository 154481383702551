import React from "react";

function Instagram(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={54.241}
      height={54.241}
      viewBox="0 0 54.241 54.241"
      {...props}
    >
      <g
        className={props.className}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
      >
        <path d="M14.56 2h25.12a12.56 12.56 0 0112.56 12.56v25.12a12.56 12.56 0 01-12.56 12.56H14.56A12.56 12.56 0 012 39.681V14.56A12.56 12.56 0 0114.56 2z" />
        <path d="M37.169 25.538a10.048 10.048 0 11-8.466-8.466 10.048 10.048 0 018.466 8.466zM40.937 13.304h0" />
      </g>
    </svg>
  );
}

export default Instagram;
