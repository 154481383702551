const categories = {
  design: [
    {
      title: "Reverse Tuck End",
      picture_mobile: require("../images/boxes/small/reverse-tuck.png"),
      picture_desktop: require("../images/boxes/large/reverse-tuck.png"),
      id: "reverse-tuck-end",
    },
    {
      title: "Straight Tuck End",
      picture_mobile: require("../images/boxes/small/straight-tuck.png"),
      picture_desktop: require("../images/boxes/large/straight-tuck.png"),
      id: "straight-tuck-end",
    },
    {
      title: "Tuck Top Snap-Lock Bottom",
      picture_mobile: require("../images/boxes/small/tuck-top-snap-lock.png"),
      picture_desktop: require("../images/boxes/large/tuck-top-snap-lock.png"),
      id: "tuck-top-snap-lock-bottom",
    },
    {
      title: "Tuck Top Auto Bottom",
      picture_mobile: require("../images/boxes/small/tuck-top-auto-bottom.png"),
      picture_desktop: require("../images/boxes/large/tuck-top-auto-bottom.png"),
      id: "tuck-top-auto-bottom",
    },
    {
      title: "Display Box",
      picture_mobile: require("../images/boxes/small/display-box.png"),
      picture_desktop: require("../images/boxes/large/display-box.png"),
      id: "display-box",
    },
    {
      title: "Gable box",
      picture_mobile: require("../images/boxes/small/gable-box.png"),
      picture_desktop: require("../images/boxes/large/gable-box.png"),
      id: "gable-box",
    },
    {
      title: "Sleeve box",
      picture_mobile: require("../images/boxes/small/sleeve-box.png"),
      picture_desktop: require("../images/boxes/large/sleeve-box.png"),
      id: "sleeve-box",
    },
    {
      title: "Six Corner box",
      picture_mobile: require("../images/boxes/small/six-corner.png"),
      picture_desktop: require("../images/boxes/large/six-corner.png"),
      id: "six-corner-box",
    },
    {
      title: "Four corner Box",
      picture_mobile: require("../images/boxes/small/four-corner.png"),
      picture_desktop: require("../images/boxes/large/four-corner.png"),
      id: "four-corner-box",
    },
    {
      title: "Hanger Box",
      picture_mobile: require("../images/boxes/small/hanger-box.png"),
      picture_desktop: require("../images/boxes/large/hanger-box.png"),
      id: "hanger-box",
    },
  ],
  material: [
    {
      title: "Corrugated",
      picture_mobile: require("../images/boxes/small/corrugated.png"),
      picture_desktop: require("../images/boxes/large/corrugated.png"),
      id: "corrugated",
    },
    {
      title: "Metallic",
      picture_mobile: require("../images/boxes/small/metallic.png"),
      picture_desktop: require("../images/boxes/large/metallic.png"),
      id: "metallic",
    },
    {
      title: "Rigid",
      picture_mobile: require("../images/boxes/small/rigid.png"),
      picture_desktop: require("../images/boxes/large/rigid.png"),
      id: "rigid",
    },
    {
      title: "Folding Board Box",
      picture_mobile: require("../images/boxes/small/folding.png"),
      picture_desktop: require("../images/boxes/large/folding.png"),
      id: "folding-board-box",
    },
    {
      title: "Eco-Friendly Box",
      picture_mobile: require("../images/boxes/small/eco-friendly.png"),
      picture_desktop: require("../images/boxes/large/eco-friendly.png"),
      id: "eco-friendly-box",
    },
  ],
  industry: [
    {
      title: "Pharmaceutical",
      picture_mobile: require("../images/boxes/small/pharma.png"),
      picture_desktop: require("../images/boxes/large/pharma.png"),
      id: "pharmaceutical",
    },
    {
      title: "Cosmetics",
      picture_mobile: require("../images/boxes/small/cosmetics.png"),
      picture_desktop: require("../images/boxes/large/cosmetics.png"),
      id: "cosmetics",
    },
    {
      title: "Tobacco & Smoke",
      picture_mobile: require("../images/boxes/small/tobacco.png"),
      picture_desktop: require("../images/boxes/large/tobacco.png"),
      id: "tobacco-and-smoke",
    },
    {
      title: "Food & Beverages",
      picture_mobile: require("../images/boxes/small/food.png"),
      picture_desktop: require("../images/boxes/large/food.png"),
      id: "food-and-beverages",
    },
    {
      title: "Gifts",
      picture_mobile: require("../images/boxes/small/gift.png"),
      picture_desktop: require("../images/boxes/large/gift.png"),
      id: "gifts",
    },
    {
      title: "Stationery",
      picture_mobile: require("../images/boxes/small/stationery.png"),
      picture_desktop: require("../images/boxes/large/stationery.png"),
      id: "stationery",
    },
    {
      title: "Logistics",
      picture_mobile: require("../images/boxes/small/logistics.png"),
      picture_desktop: require("../images/boxes/large/logistics.png"),
      id: "logistics",
    },
  ],
};

export default categories;
