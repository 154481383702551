import React, { useState, useRef, useEffect } from "react";
const Ribbon = require("../../images/ribbon.png");
const Step1 = require("../../images/step-1.png");
const Step2 = require("../../images/step-2.png");
const Step3 = require("../../images/step-3.png");

import "./styles.css";

export default function SplitScroll({
  title,
  subtitle,
  description,
  step1,
  step2,
  step3,
  ribbon,
}) {
  const [selectedStepImage, setSelectedStepImage] = useState(Step1);
  const [ribbonPosition, setRibbonPosition] = useState(0);
  const [ribbonHeight, setRibbonHeight] = useState(20);
  const stepsContainer = useRef();
  const step1Ref = useRef();
  const step2Ref = useRef();
  const step3Ref = useRef();
  const [selectedStep, setSelectedStep] = useState(step1Ref);

  function stepClicked(ref) {
    setSelectedStep(ref);
  }

  useEffect(() => {
    // change active step class
    step1Ref.current.className = "step_big";
    step2Ref.current.className = "step_big";
    step3Ref.current.className = "step_big";
    selectedStep.current.className = selectedStep.current.className + " active";
    const stepName = selectedStep.current.attributes["data-name"].value;
    if (stepName === "step1") {
      setSelectedStepImage(Step1);
    } else if (stepName === "step2") {
      setSelectedStepImage(Step2);
    } else {
      setSelectedStepImage(Step3);
    }

    // set ribbon height and position
    const height = selectedStep.current?.clientHeight;
    const offsetTopContainer = stepsContainer.current?.offsetTop;
    const offsetTop = selectedStep.current?.offsetTop;
    if (height) {
      setRibbonHeight(height);
    }
    if (offsetTop) {
      setRibbonPosition(offsetTop - offsetTopContainer);
    }
  }, [selectedStep]);

  return (
    <div>
      <div className="process hideOnLarge">
        <div className="titleWrapper">
          {ribbon ? <img src={Ribbon} alt="Ribbon" className="ribbon" /> : null}
          <h2 className="h1">{title}</h2>
        </div>
        <p className="bold centered subtitle">{subtitle}</p>
        <div className="step">
          <img src={Step1} alt="Process_Step_1" />
          <h2 className="h1 stepTitle">{step1.title}</h2>
          <p className="stepDesc">{step1.description}</p>
        </div>
        <div className="step">
          <img src={Step2} alt="Process_Step_2" />
          <h2 className="h1 stepTitle">{step2.title}</h2>
          <p className="stepDesc">{step2.description}</p>
        </div>
        <div className="step">
          <img src={Step3} alt="Process_Step_3" />
          <h2 className="h1 stepTitle">{step3.title}</h2>
          <p className="stepDesc">{step3.description}</p>
        </div>
      </div>
      <div className="process hideOnSmall">
        <div className="titleWrapper">
          {ribbon ? <img src={Ribbon} alt="Ribbon" className="ribbon" /> : null}
          <h2 className="h1">{title}</h2>
        </div>
        <p className="bold centered subtitle">{subtitle}</p>
        <p className="processDesc">{description}</p>
        <div className="stepsWrapper">
          <div ref={stepsContainer} className="stepsInner">
            <span
              className="ribbon-strip"
              style={{ height: ribbonHeight, top: ribbonPosition }}
            />
            <div className="steps">
              <div
                ref={step1Ref}
                className="step_big active"
                onClick={() => stepClicked(step1Ref)}
                onKeyUp={() => stepClicked(step1Ref)}
                role="button"
                tabIndex="0"
                aria-label="Our Process Step 1"
                data-name="step1"
              >
                <h2 className="h1 step_big_title" data-name="step1">
                  {step1.title}
                </h2>
                <p className="step_big_desc" data-name="step1">
                  {step1.description}
                </p>
              </div>
              <div
                ref={step2Ref}
                className="step_big"
                onClick={() => stepClicked(step2Ref)}
                onKeyUp={() => stepClicked(step2Ref)}
                role="button"
                tabIndex="0"
                aria-label="Our Process Step 2"
                data-name="step2"
              >
                <h2 className="h1 step_big_title" data-name="step2">
                  {step2.title}
                </h2>
                <p className="step_big_desc" data-name="step2">
                  {step2.description}
                </p>
              </div>
              <div
                ref={step3Ref}
                className="step_big"
                onClick={() => stepClicked(step3Ref)}
                onKeyUp={() => stepClicked(step3Ref)}
                role="button"
                tabIndex="0"
                aria-label="Our Process Step 3"
                data-name="step3"
              >
                <h2 className="h1 step_big_title" data-name="step3">
                  {step3.title}
                </h2>
                <p className="step_big_desc" data-name="step3">
                  {step3.description}
                </p>
              </div>
            </div>
          </div>
          <div className="selectedImageWrapper">
            <img src={selectedStepImage} alt="Process_Step" />
          </div>
        </div>
      </div>
    </div>
  );
}
