import React from "react";
import { Link } from "react-router-dom";
const AttrSpeed = require("../../images/attribute_speedy_turnarounds.png");
const AttrPrices = require("../../images/attribute_competitive_prices.png");
const AttrBrightOffset = require("../../images/attribute_bright_offset_printing.png");
const AttrCustomization = require("../../images/attribute_full_customization.png");
const AttrQuotes = require("../../images/attribute_instant_quotes.png");
const AttrOrders = require("../../images/attribute_low_min_orders.png");
const AttrConsult = require("../../images/attribute_personalized_consultation.png");
const AttrQuality = require("../../images/attribute_quality_checks.png");
const AttrDie = require("../../images/attribute_zero_die.png");

import "./styles.css";

export default function CompanyAttributes({ optionalTitle }) {
  return (
    <>
      <div className="companyAttributes">
        <h2 className="h1 title">{optionalTitle}</h2>
        <h2 className="h1">We Guarantee</h2>
        <div className="attributeGrid">
          <div className="attribute">
            <img src={AttrPrices} alt="Competitive_Prices" />
            <p className="bold caption">Competitive Prices</p>
            <p className="gray">
              Don&apos;t believe us? Get an instant quote today to find out
            </p>
          </div>
          <div className="attribute">
            <img src={AttrQuality} alt="Quality_Checks" />
            <p className="bold caption">Quality checks</p>
            <p className="gray">
              We follow rigorous standards to ensure quality and durability
            </p>
          </div>
          <div className="attribute">
            <img src={AttrSpeed} alt="Speedy_Turnaround" />
            <p className="bold caption">Speedy turnarounds</p>
            <p className="gray">
              Get your order shipped in as early as 7 business days
            </p>
          </div>
          <div className="attribute">
            <img src={AttrCustomization} alt="Full_Customization" />
            <p className="bold caption">full customization</p>
            <p className="gray">
              Tell us the shape and size of your box, we turn your ideas into
              reality
            </p>
          </div>
          <div className="attribute">
            <img src={AttrConsult} alt="Personalized_Consultation" />
            <p className="bold caption">PERSONALIZED CONSULTATION</p>
            <p className="gray">
              We provide consultation based on the unique design of your
              products
            </p>
          </div>
          <div className="attribute">
            <img src={AttrQuotes} alt="Instant_Quotes" />
            <p className="bold caption">instant quotes</p>
            <p className="gray">
              Get a personalized quote from us within 2 business days
            </p>
          </div>
          <div className="attribute hideOnSmall">
            <img src={AttrOrders} alt="Low_Min_No_Max_Orders" />
            <p className="bold caption">
              Low minimum,
              <br />
              no maximum orders
            </p>
            <p className="gray">Place an order for as low as 100 boxes</p>
          </div>
          <div className="attribute hideOnSmall">
            <img src={AttrDie} alt="Zero_Die_Plate_Charge" />
            <p className="bold caption">zero die &amp; plate charges</p>
            <p className="gray">
              Transparency from the begining, no hidden fee for quality services
            </p>
          </div>
          <div className="attribute hideOnSmall">
            <img src={AttrBrightOffset} alt="Bright_OffsetPrinting" />
            <p className="bold caption">bright offset printing</p>
            <p className="gray">
              Bright offset printing to get your brand stand out from
              competition
            </p>
          </div>
        </div>
        <Link to="/about">
          <p className="blue bold centered">and more...</p>
        </Link>
      </div>
    </>
  );
}
