import React from "react";
import { Link } from "react-router-dom";
import BoxCarousel from "../BoxCarousel/BoxCarousel";

import "./styles.css";

export default function SubcategoryDetails({ category }) {
  return (
    <>
      <div className="subcategoryContainer">
        <BoxCarousel images={category.slider_images} />
        <div className="subcategoryDetails">
          <h1 className="h1 left subcategoryTitle">{category.name}</h1>
          <div className="subcategoryDetailsWrapper">
            <div className="aboutSubcategory">{category.description}</div>
            <div className="subcategoryBoxes">
              <h2 className="h2 left">Sub-Categories</h2>
              {category.subCategories.map((box, index) => {
                return (
                  <div className="subcategoryBoxWrapper" key={index.toString()}>
                    <Link to={`${location.pathname}/${box.id}`}>
                      <div className="subcategoryBox">
                        <img
                          className="subcategoryBoxImage"
                          src={box.details.thumbnail}
                          alt="subcategory box"
                        />
                        <p className="subcategoryBoxName">
                          {box.details.name}
                        </p>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
