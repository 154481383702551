import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

export default function RequestKit() {
  return (
    <div className="requestKit">
      <h2 className="h1 white">ORDER YOUR SAMPLE KIT</h2>
      <p className="white centered">
        A collection of our handpicked pre-printed custom packaging samples for
        you to inspect the quality of our materials and help you decide what
        kind of packaging will be suitable for your products.
      </p>
      <Link to="/get-sample-kit">
        <button>Order Now</button>
      </Link>
    </div>
  );
}
