import React, { createContext, useState, useEffect } from "react";

export const UIContext = createContext();

export const UIContextProvider = (props) => {
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(null);
  const [footerType, setFooterType] = useState(FooterTypes.CONTACT);
  const [backgroundColor, setBackgroundColor] = useState(BackgroundType.LIGHT);
  const [loadingDirection, setLoadingDirection] = useState(
    LoadingDirections.LEFT_IN_COMPACT
  );

  function toggleQuoteModal() {
    setIsQuoteModalOpen(!isQuoteModalOpen);
  }

  function openQuoteModal() {
    setIsQuoteModalOpen(true);
  }

  function loadLoader(direction) {
    setLoadingDirection(direction);
  }

  function closeLoader() {
    switch (loadingDirection) {
      case LoadingDirections.LEFT_IN:
        setLoadingDirection(LoadingDirections.LEFT_OUT);
        break;
      case LoadingDirections.LEFT_IN_COMPACT:
        setLoadingDirection(LoadingDirections.LEFT_OUT_COMPACT);
        break;
      default:
        setLoadingDirection(LoadingDirections.LEFT_OUT);
        break;
    }
  }

  return (
    <UIContext.Provider
      value={{
        isQuoteModalOpen,
        toggleQuoteModal,
        openQuoteModal,
        footerType,
        setFooterType,
        backgroundColor,
        setBackgroundColor,
        loadingDirection,
        loadLoader,
        closeLoader,
      }}
    >
      {props.children}
    </UIContext.Provider>
  );
};

export const FooterTypes = {
  KIT: "kit",
  SEARCH: "search",
  NOTFOUND: "notfound",
  CONTACT: "contact",
};

export const BackgroundType = {
  DARK: "dark",
  LIGHT: "light",
};

export const LoadingDirections = {
  LEFT_IN: "left-in",
  LEFT_OUT: "left-out",
  LEFT_IN_COMPACT: "left-in-compact",
  LEFT_OUT_COMPACT: "left-out-compact",
};

export function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
