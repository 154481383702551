import React from "react";

function Logo(props) {
  return (
    <svg
      width="708"
      viewBox="0 0 708 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.737 64.8684C129.737 100.694 100.694 129.737 64.8684 129.737C29.0426 129.737 0 100.694 0 64.8684C0 29.0426 29.0426 0 64.8684 0C100.694 0 129.737 29.0426 129.737 64.8684ZM62.1744 96.0187L28.2759 84.4612V80.9357C28.2759 79.5056 27.1076 78.3356 25.6798 78.3356C24.252 78.3356 23.0838 79.5056 23.0838 80.9357V86.3853C23.0838 87.5033 23.8366 88.5174 24.927 88.8554L62.1744 101.487V115.766C62.1744 117.196 63.3426 118.366 64.7705 118.366C66.1983 118.366 67.3665 117.196 67.3665 115.74V101.791L104.934 88.7836C105.842 88.3415 106.439 87.4315 106.439 86.4175V57.598C106.439 56.7139 105.998 55.8819 105.245 55.4139C104.492 54.9458 103.558 54.8678 102.753 55.2579L65.0879 66.2093L28.2759 54.3257V46.3694L65.3067 28.8076L101.247 38.855V41.9232C101.247 43.3532 102.416 44.5233 103.843 44.5233C105.271 44.5233 106.439 43.3532 106.439 41.9232V37.009C106.439 35.9169 105.764 34.9289 104.726 34.5648L65.9557 23.5294C65.2288 23.2693 64.45 23.3473 63.801 23.7114L24.4077 42.5732C23.577 43.0412 23.0838 43.8993 23.0838 44.8353V56.1978C23.0838 57.3159 23.8107 58.3039 24.875 58.668L62.1744 71.1666V96.0187ZM67.3665 96.2201L101.247 84.7534V61.6801L67.3665 71.5233V96.2201Z"
        fill={props.color}
      />
      <path
        d="M179.198 92.0139C175.095 92.0139 171.117 91.4703 167.262 90.383C163.456 89.2462 160.392 87.7882 158.069 86.009L162.146 76.9645C164.37 78.5954 167.015 79.9052 170.079 80.8936C173.143 81.8821 176.207 82.3763 179.272 82.3763C182.682 82.3763 185.203 81.8821 186.833 80.8936C188.464 79.8557 189.28 78.4966 189.28 76.8162C189.28 75.5806 188.786 74.5674 187.797 73.7766C186.858 72.9364 185.623 72.2692 184.09 71.775C182.608 71.2808 180.581 70.7371 178.011 70.144C174.057 69.205 170.82 68.2659 168.3 67.3269C165.779 66.3878 163.604 64.8804 161.776 62.8046C159.996 60.7288 159.107 57.9611 159.107 54.5014C159.107 51.4866 159.922 48.7683 161.553 46.3466C163.184 43.8754 165.631 41.9231 168.893 40.4899C172.204 39.0566 176.232 38.3399 180.977 38.3399C184.288 38.3399 187.525 38.7353 190.689 39.5261C193.852 40.3169 196.619 41.4536 198.992 42.9363L195.285 52.055C190.491 49.3367 185.697 47.9775 180.903 47.9775C177.542 47.9775 175.046 48.5212 173.415 49.6085C171.833 50.6958 171.043 52.1291 171.043 53.9084C171.043 55.6876 171.957 57.022 173.786 57.9117C175.664 58.7519 178.506 59.5921 182.311 60.4323C186.265 61.3713 189.502 62.3104 192.023 63.2494C194.544 64.1885 196.693 65.6712 198.473 67.6975C200.301 69.7239 201.216 72.4669 201.216 75.9266C201.216 78.892 200.376 81.6103 198.695 84.0815C197.064 86.5032 194.593 88.4307 191.282 89.864C187.97 91.2973 183.942 92.0139 179.198 92.0139Z"
        fill={props.color}
      />
      <path
        d="M208.607 36.1159H220.172V91.1243H208.607V36.1159Z"
        fill={props.color}
      />
      <path
        d="M230.905 51.2395H242.47V91.1243H230.905V51.2395ZM236.688 45.6793C234.563 45.6793 232.833 45.0615 231.498 43.826C230.164 42.5904 229.497 41.0582 229.497 39.2296C229.497 37.4009 230.164 35.8688 231.498 34.6332C232.833 33.3976 234.563 32.7798 236.688 32.7798C238.813 32.7798 240.543 33.3729 241.877 34.559C243.212 35.7452 243.879 37.2279 243.879 39.0072C243.879 40.9347 243.212 42.5409 241.877 43.826C240.543 45.0615 238.813 45.6793 236.688 45.6793Z"
        fill={props.color}
      />
      <path
        d="M272.479 91.7174C268.229 91.7174 264.398 90.8525 260.988 89.1227C257.627 87.3434 254.983 84.8969 253.055 81.7833C251.177 78.6696 250.238 75.1358 250.238 71.1819C250.238 67.228 251.177 63.6942 253.055 60.5805C254.983 57.4669 257.627 55.0451 260.988 53.3153C264.398 51.536 268.229 50.6464 272.479 50.6464C276.68 50.6464 280.337 51.536 283.451 53.3153C286.614 55.0451 288.912 57.541 290.346 60.803L281.375 65.6218C279.299 61.9644 276.309 60.1357 272.405 60.1357C269.39 60.1357 266.894 61.1242 264.917 63.1011C262.94 65.0781 261.952 67.7717 261.952 71.1819C261.952 74.5921 262.94 77.2857 264.917 79.2627C266.894 81.2396 269.39 82.2281 272.405 82.2281C276.359 82.2281 279.349 80.3994 281.375 76.7421L290.346 81.635C288.912 84.7981 286.614 87.2693 283.451 89.0485C280.337 90.8278 276.68 91.7174 272.479 91.7174Z"
        fill={props.color}
      />
      <path
        d="M313.84 75.4818L308.28 80.9678V91.1243H296.715V36.1159H308.28V67.2527L325.183 51.2395H338.972L322.366 68.1424L340.455 91.1243H326.443L313.84 75.4818Z"
        fill={props.color}
      />
      <path
        d="M381.562 39.2299C388.185 39.2299 393.374 40.8115 397.131 43.9746C400.887 47.1377 402.765 51.4869 402.765 57.0224C402.765 62.5578 400.887 66.9071 397.131 70.0702C393.374 73.1839 388.185 74.7407 381.562 74.7407H367.625V91.1246H362.139V39.2299H381.562ZM381.414 69.9219C386.554 69.9219 390.483 68.8099 393.201 66.5858C395.92 64.3124 397.279 61.1245 397.279 57.0224C397.279 52.8214 395.92 49.6088 393.201 47.3848C390.483 45.1113 386.554 43.9746 381.414 43.9746H367.625V69.9219H381.414Z"
        fill={props.color}
      />
      <path
        d="M426.794 51.8329C431.885 51.8329 435.789 53.1179 438.507 55.6879C441.226 58.2086 442.585 61.9647 442.585 66.9565V91.1246H437.544V85.0455C436.357 87.0719 434.603 88.6535 432.28 89.7902C430.007 90.927 427.288 91.4953 424.125 91.4953C419.776 91.4953 416.316 90.4574 413.746 88.3816C411.176 86.3058 409.891 83.5628 409.891 80.1526C409.891 76.8412 411.077 74.1724 413.45 72.146C415.871 70.1196 419.702 69.1064 424.941 69.1064H437.321V66.7341C437.321 63.3733 436.382 60.828 434.504 59.0982C432.626 57.3189 429.883 56.4293 426.275 56.4293C423.804 56.4293 421.432 56.8494 419.158 57.6896C416.885 58.4804 414.932 59.5924 413.301 61.0257L410.929 57.0965C412.906 55.4161 415.278 54.1311 418.046 53.2415C420.814 52.3024 423.73 51.8329 426.794 51.8329ZM424.941 87.3437C427.906 87.3437 430.451 86.6765 432.577 85.3421C434.702 83.9582 436.283 81.9813 437.321 79.4113V73.0356H425.089C418.417 73.0356 415.081 75.3585 415.081 80.0043C415.081 82.2778 415.945 84.0818 417.675 85.4162C419.405 86.7012 421.827 87.3437 424.941 87.3437Z"
        fill={props.color}
      />
      <path
        d="M473.188 91.4953C469.333 91.4953 465.873 90.6551 462.809 88.9747C459.794 87.2943 457.422 84.9467 455.692 81.9319C453.962 78.8676 453.097 75.4327 453.097 71.627C453.097 67.8214 453.962 64.4112 455.692 61.3964C457.422 58.3815 459.794 56.0339 462.809 54.3535C465.873 52.6731 469.333 51.8329 473.188 51.8329C476.548 51.8329 479.539 52.5001 482.158 53.8346C484.827 55.1196 486.927 57.0224 488.46 59.543L484.53 62.2119C483.245 60.2843 481.614 58.8511 479.637 57.912C477.66 56.9235 475.511 56.4293 473.188 56.4293C470.371 56.4293 467.825 57.0718 465.552 58.3568C463.328 59.5924 461.573 61.3717 460.288 63.6946C459.053 66.0175 458.435 68.6616 458.435 71.627C458.435 74.6419 459.053 77.3108 460.288 79.6337C461.573 81.9072 463.328 83.6864 465.552 84.9714C467.825 86.207 470.371 86.8248 473.188 86.8248C475.511 86.8248 477.66 86.3553 479.637 85.4162C481.614 84.4772 483.245 83.0439 484.53 81.1164L488.46 83.7853C486.927 86.3059 484.827 88.2334 482.158 89.5678C479.489 90.8528 476.499 91.4953 473.188 91.4953Z"
        fill={props.color}
      />
      <path
        d="M512.677 71.5529L503.484 80.0043V91.1246H498.22V36.1162H503.484V73.4063L526.763 52.1294H533.287L516.606 68.0686L534.843 91.1246H528.394L512.677 71.5529Z"
        fill={props.color}
      />
      <path
        d="M555.662 51.8329C560.753 51.8329 564.657 53.1179 567.375 55.6879C570.094 58.2086 571.453 61.9647 571.453 66.9565V91.1246H566.412V85.0455C565.226 87.0719 563.471 88.6535 561.148 89.7902C558.875 90.927 556.156 91.4953 552.993 91.4953C548.644 91.4953 545.184 90.4574 542.614 88.3816C540.044 86.3058 538.759 83.5628 538.759 80.1526C538.759 76.8412 539.945 74.1724 542.318 72.146C544.739 70.1196 548.57 69.1064 553.809 69.1064H566.189V66.7341C566.189 63.3733 565.25 60.828 563.372 59.0982C561.494 57.3189 558.751 56.4293 555.143 56.4293C552.672 56.4293 550.3 56.8494 548.026 57.6896C545.753 58.4804 543.8 59.5924 542.169 61.0257L539.797 57.0965C541.774 55.4161 544.146 54.1311 546.914 53.2415C549.682 52.3024 552.598 51.8329 555.662 51.8329ZM553.809 87.3437C556.774 87.3437 559.319 86.6765 561.445 85.3421C563.57 83.9582 565.151 81.9813 566.189 79.4113V73.0356H553.957C547.285 73.0356 543.949 75.3585 543.949 80.0043C543.949 82.2778 544.814 84.0818 546.543 85.4162C548.273 86.7012 550.695 87.3437 553.809 87.3437Z"
        fill={props.color}
      />
      <path
        d="M621.998 52.1294V86.38C621.998 93.0027 620.367 97.8957 617.105 101.059C613.893 104.271 609.024 105.878 602.501 105.878C598.893 105.878 595.458 105.334 592.196 104.247C588.983 103.209 586.364 101.751 584.337 99.8726L587.006 95.8693C588.884 97.5497 591.158 98.8594 593.827 99.7985C596.545 100.738 599.387 101.207 602.352 101.207C607.295 101.207 610.927 100.046 613.25 97.7227C615.573 95.4492 616.735 91.8907 616.735 87.0472V82.0801C615.104 84.5513 612.954 86.4294 610.285 87.7144C607.665 88.9994 604.749 89.6419 601.537 89.6419C597.879 89.6419 594.543 88.8512 591.529 87.2696C588.563 85.6386 586.215 83.3899 584.486 80.5233C582.805 77.6073 581.965 74.3206 581.965 70.6633C581.965 67.0059 582.805 63.744 584.486 60.8774C586.215 58.0109 588.563 55.7868 591.529 54.2052C594.494 52.6237 597.83 51.8329 601.537 51.8329C604.848 51.8329 607.838 52.5001 610.507 53.8346C613.176 55.169 615.326 57.0965 616.957 59.6171V52.1294H621.998ZM602.056 84.9714C604.873 84.9714 607.418 84.3783 609.692 83.1922C611.965 81.9566 613.72 80.2515 614.955 78.0768C616.24 75.9022 616.883 73.431 616.883 70.6633C616.883 67.8956 616.24 65.4491 614.955 63.3239C613.72 61.1493 611.965 59.4689 609.692 58.2827C607.468 57.0471 604.922 56.4293 602.056 56.4293C599.239 56.4293 596.693 57.0224 594.42 58.2085C592.196 59.3947 590.441 61.0751 589.156 63.2498C587.921 65.4244 587.303 67.8956 587.303 70.6633C587.303 73.431 587.921 75.9022 589.156 78.0768C590.441 80.2515 592.196 81.9566 594.42 83.1922C596.693 84.3783 599.239 84.9714 602.056 84.9714Z"
        fill={props.color}
      />
      <path
        d="M670.672 73.258H638.052C638.348 77.3108 639.905 80.5974 642.722 83.118C645.54 85.5892 649.098 86.8248 653.398 86.8248C655.82 86.8248 658.044 86.4047 660.07 85.5645C662.097 84.6749 663.851 83.3899 665.334 81.7095L668.299 85.1197C666.569 87.1955 664.395 88.777 661.775 89.8643C659.205 90.9517 656.363 91.4953 653.25 91.4953C649.246 91.4953 645.688 90.6551 642.574 88.9747C639.51 87.2449 637.113 84.8726 635.383 81.8577C633.653 78.8429 632.788 75.4327 632.788 71.627C632.788 67.8214 633.604 64.4112 635.235 61.3964C636.915 58.3815 639.189 56.0339 642.055 54.3535C644.971 52.6731 648.233 51.8329 651.841 51.8329C655.449 51.8329 658.686 52.6731 661.553 54.3535C664.419 56.0339 666.668 58.3815 668.299 61.3964C669.93 64.3618 670.746 67.772 670.746 71.627L670.672 73.258ZM651.841 56.3552C648.085 56.3552 644.922 57.566 642.352 59.9878C639.831 62.3601 638.398 65.4738 638.052 69.3289H665.704C665.358 65.4738 663.9 62.3601 661.33 59.9878C658.81 57.566 655.647 56.3552 651.841 56.3552Z"
        fill={props.color}
      />
      <path
        d="M691.912 91.4953C688.749 91.4953 685.71 91.0505 682.794 90.1609C679.927 89.2218 677.678 88.0604 676.047 86.6765L678.42 82.5249C680.051 83.81 682.102 84.8726 684.573 85.7128C687.044 86.5035 689.614 86.8989 692.283 86.8989C695.842 86.8989 698.461 86.3553 700.141 85.268C701.871 84.1312 702.736 82.5497 702.736 80.5233C702.736 79.09 702.267 77.978 701.328 77.1872C700.389 76.347 699.202 75.7292 697.769 75.3338C696.336 74.889 694.433 74.4689 692.061 74.0735C688.898 73.4804 686.352 72.8873 684.425 72.2943C682.497 71.6518 680.842 70.5892 679.458 69.1064C678.123 67.6237 677.456 65.5727 677.456 62.9532C677.456 59.6913 678.815 57.0224 681.533 54.9466C684.252 52.8708 688.033 51.8329 692.876 51.8329C695.397 51.8329 697.917 52.1789 700.438 52.8708C702.959 53.5133 705.034 54.3782 706.665 55.4655L704.367 59.6913C701.155 57.4672 697.324 56.3552 692.876 56.3552C689.515 56.3552 686.97 56.9482 685.24 58.1344C683.56 59.3206 682.72 60.8774 682.72 62.8049C682.72 64.2876 683.189 65.4738 684.128 66.3634C685.117 67.2531 686.328 67.9203 687.761 68.3651C689.194 68.7605 691.171 69.1806 693.692 69.6254C696.805 70.2185 699.301 70.8116 701.179 71.4046C703.057 71.9977 704.664 73.0109 705.998 74.4442C707.333 75.8775 708 77.8544 708 80.375C708 83.7853 706.567 86.5035 703.7 88.5299C700.883 90.5069 696.954 91.4953 691.912 91.4953Z"
        fill={props.color}
      />
    </svg>
  );
}

export default Logo;
