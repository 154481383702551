import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";

const ArrowLeft = require("../../images/arrow_left.png");
const ArrowRight = require("../../images/arrow_right.png");
const Corgi = require("../../images/corgi.png");

const SliderArrow = ({ classN, to, onClick, imgSrc }) => (
  <button
    onClick={onClick}
    className={`slick-arrow slick-${classN}`}
    aria-label={to}
  >
    <img className="arrowIcon" icon={to} src={imgSrc} width={30} alt="Arrow" />
  </button>
);

export default function ReviewCarousel() {
  const [reviews] = useState([
    {
      author: "1 Reviewer",
      role: "Founder",
      company: "Crafted",
      photo: Corgi,
      review:
        "1 quality is excellent and no doubt your company prides itself on using the best quality and standards.",
    },
    {
      author: "2 Reviewer",
      role: "Founder",
      company: "Crafted",
      photo: Corgi,
      review:
        "2 quality is excellent and no doubt your company prides itself on using the best quality and standards.",
    },
    {
      author: "3 Reviewer",
      role: "Founder",
      company: "Crafted",
      photo: Corgi,
      review:
        "3 quality is excellent and no doubt your company prides itself on using the best quality and standards.",
    },
    {
      author: "4 Reviewer",
      role: "Founder",
      company: "Crafted",
      photo: Corgi,
      review:
        "4 quality is excellent and no doubt your company prides itself on using the best quality and standards.",
    },
    {
      author: "5 Reviewer",
      role: "Founder",
      company: "Crafted",
      photo: Corgi,
      review:
        "5 quality is excellent and no doubt your company prides itself on using the best quality and standards.",
    },
    {
      author: "6 Reviewer",
      role: "Founder",
      company: "Crafted",
      photo: Corgi,
      review:
        "6 quality is excellent and no doubt your company prides itself on using the best quality and standards.",
    },
  ]);
  const [selectedReview, setSelectedReview] = useState({});
  const [sliderSettings] = useState({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerPadding: 0,
    centerMode: true,
    prevArrow: <SliderArrow to="prev" imgSrc={ArrowLeft} classN="prev" />,
    nextArrow: <SliderArrow to="next" imgSrc={ArrowRight} classN="next" />,
    afterChange: (current) => setSelectedReview(reviews[current]),
    responsive: [
      {
        breakpoint: 989,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  useEffect(() => {
    setSelectedReview(reviews[0]);
  }, []);

  function getReviews() {
    const rev = reviews.map((review, index) => {
      return (
        <div key={index.toString()}>
          <div className="reviewItem">
            <div className="reviewerPhoto">
              <img src={review.photo} alt="Reviewer" />
            </div>
            <div className="authorDetails">
              <h3 className="h3">{review.author}</h3>
              <p>{review.role}</p>
              <p>{review.company}</p>
            </div>
          </div>
        </div>
      );
    });
    return rev;
  }

  return (
    <>
      <div className="reviews">
        <p className="selectedReview">{selectedReview.review}</p>
      </div>
      <Slider className="reviewsSlider" {...sliderSettings}>
        {getReviews()}
      </Slider>
    </>
  );
}
