import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { UIContext, FooterTypes } from "../../context/UIContext";

import "./styles.css";

export default function RequestKit() {
  const { setFooterType, closeLoader } = useContext(UIContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [success, setSuccess] = useState(null);
  const [defaultFailureMessage] = useState(
    "Oh no! Something went wrong. Please try again or write to us at info@slickpackages.com"
  );
  const [failureMessage, setFailureMessage] = useState(defaultFailureMessage);

  useEffect(() => {
    setFooterType(FooterTypes.CONTACT);
    closeLoader();
  }, []);

  function submitForm(event) {
    event.preventDefault();
    const validation = validateForm();
    if (validation.isValid) {
      // proceed to submit form

      axios
        .post(
          `https://contact-slick-packages.netlify.app/.netlify/functions/api/request-kit`,
          {
            name: name,
            emailAddress: email,
            contactNumber: contactNumber,
            shippingAddress: shippingAddress,
            city: city,
            state: state,
            postalCode: postalCode,
            country: country,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setSuccess(true);
          } else {
            setFailureMessage(defaultFailureMessage);
            setSuccess(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              setFailureMessage("Please enter a valid email address");
              setSuccess(false);
            } else {
              setFailureMessage(defaultFailureMessage);
              setSuccess(false);
            }
          }
        });
    } else {
      setFailureMessage(validation.message);
      setSuccess(false);
    }
  }

  function validateForm() {
    if (name === "") {
      return { isValid: false, message: "Name is required" };
    }
    if (email === "") {
      return { isValid: false, message: "Email address is required" };
    }
    if (shippingAddress === "") {
      return { isValid: false, message: "Shipping address is required" };
    }
    if (city === "") {
      return { isValid: false, message: "City is required" };
    }
    if (state === "") {
      return { isValid: false, message: "State is required" };
    }
    if (country === "") {
      return { isValid: false, message: "Country is required" };
    }
    if (contactNumber === "") {
      return { isValid: false, message: "Contact number is required" };
    }
    return { isValid: true, message: "Validation successful" };
  }

  function clearStatus() {
    setSuccess(null);
    setFailureMessage(defaultFailureMessage);
  }

  return (
    <>
      <div className="requestKitTextContainer">
        <h1 className="h1">Get Your Sample Kit</h1>
        <p className="bold caption centered">
          delivered to you at your doorstep
        </p>
        <p className="centered requestKitDescription">
          Our sample kit * contains a full-range of styles, materials and print
          quality. Request yours today to get it delivered in 3-7 working days.
        </p>
      </div>
      <div className="sampleKitFormWrapper">
        <form className="sampleKitForm" onSubmit={submitForm}>
          {success === true ? (
            <p className="status success">
              Request received! We will notify you as soon as we ship your
              sample kit.
            </p>
          ) : success === false ? (
            <p className="status failure">{failureMessage}</p>
          ) : (
            <p className="status" />
          )}
          <p className={name === "" ? "label invisible" : "label visible"}>
            Name*
          </p>
          <input
            type="text"
            name="name"
            placeholder="Full Name*"
            value={name}
            onChange={(event) => setName(event.target.value)}
            onKeyUp={() => clearStatus()}
            required={true}
          />
          <p className={email === "" ? "label invisible" : "label visible"}>
            Email*
          </p>
          <input
            type="email"
            name="email"
            placeholder="Email*"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onKeyUp={() => clearStatus()}
            required={true}
          />
          <p
            className={
              shippingAddress === "" ? "label invisible" : "label visible"
            }
          >
            Shipping Address*
          </p>
          <input
            type="text"
            name="shipping_address"
            placeholder="Shipping Address*"
            value={shippingAddress}
            onChange={(event) => setShippingAddress(event.target.value)}
            onKeyUp={() => clearStatus()}
            required={true}
          />
          <p className={city === "" ? "label invisible" : "label visible"}>
            City*
          </p>
          <input
            type="text"
            name="city"
            placeholder="City/Town*"
            value={city}
            onChange={(event) => setCity(event.target.value)}
            onKeyUp={() => clearStatus()}
            required={true}
          />
          <p className={state === "" ? "label invisible" : "label visible"}>
            State*
          </p>
          <input
            type="text"
            name="state"
            placeholder="State/Province*"
            value={state}
            onChange={(event) => setState(event.target.value)}
            onKeyUp={() => clearStatus()}
            required={true}
          />
          <p
            className={postalCode === "" ? "label invisible" : "label visible"}
          >
            Postal Code
          </p>
          <input
            type="text"
            name="code"
            placeholder="Postal Code"
            value={postalCode}
            onChange={(event) => setPostalCode(event.target.value)}
            onKeyUp={() => clearStatus()}
          />
          <p className={country === "" ? "label invisible" : "label visible"}>
            Country*
          </p>
          <input
            type="text"
            name="country"
            placeholder="Country*"
            value={country}
            onChange={(event) => setCountry(event.target.value)}
            onKeyUp={() => clearStatus()}
            required={true}
          />
          <p
            className={
              contactNumber === "" ? "label invisible" : "label visible"
            }
          >
            Contact Number*
          </p>
          <input
            type="tel"
            name="phone"
            placeholder="Contact Number*"
            value={contactNumber}
            onChange={(event) => setContactNumber(event.target.value)}
            onKeyUp={() => clearStatus()}
            required={true}
          />
          <p className="centered fee">Delivery Fee: $10</p>
          <button type="submit">Deliver</button>
        </form>
      </div>
      <p className="centered disclaimer">
        * Sample kits are <strong>not</strong> your custom designs. Each sample
        kit contains swatches and assembled boxes that showcase our full range
        of styles, materials, and print quality.
      </p>
    </>
  );
}
