import React from "react";

function Twitter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={58.475}
      height={48.445}
      viewBox="0 0 58.475 48.445"
      {...props}
    >
      <path
        d="M56.475 2.038a26.99 26.99 0 01-7.775 3.79 11.093 11.093 0 00-19.462 7.428v2.472A26.4 26.4 0 016.952 4.514s-9.9 22.285 12.381 32.19A28.822 28.822 0 012 41.656c22.285 12.381 49.523 0 49.523-28.476a11.143 11.143 0 00-.2-2.055 19.116 19.116 0 005.15-9.087z"
        className={props.className}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
      />
    </svg>
  );
}

export default Twitter;
