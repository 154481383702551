import firebase from "firebase/app";
import "firebase/firestore";
import 'firebase/auth';

firebase.initializeApp({
  apiKey: "AIzaSyAgxyflqd90lKwtdxuo6tw8LXy0u-VabcQ",
  authDomain: "slick-packages.firebaseapp.com",
  databaseURL: "https://slick-packages.firebaseio.com",
  projectId: "slick-packages",
  storageBucket: "slick-packages.appspot.com",
  messagingSenderId: "273937155849",
  appId: "1:273937155849:web:5257c078cb250ce3bcb5d8",
  measurementId: "G-479NHXKNCP",
});

const database = firebase.firestore();

const DesignCollection = database.collection("byDesign");
const MaterialCollection = database.collection("byMaterial");
const IndustryCollection = database.collection("byIndustry");
const IndustrySubCategories = database.collectionGroup("subCategories");
const FeaturedCollection = database.collectionGroup("featured");

const initAuth = () => 
firebase.auth().signInAnonymously()
.then(() => {
  console.log("Signed in");
})
.catch((error) => {
  console.log("Could not sign in: ", error);
});

export {
  DesignCollection,
  MaterialCollection,
  IndustryCollection,
  IndustrySubCategories,
  FeaturedCollection,
  initAuth
};
