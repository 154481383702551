import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { UIContext, FooterTypes } from "../../context/UIContext";
import Social from "../../components/Social/Social";

import "./styles.css";

export default function Contact() {
  const { setFooterType, closeLoader } = useContext(UIContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(null);
  const [defaultFailureMessage] = useState(
    "Oh no! Something went wrong. Please try again or write to us at info@slickpackages.com"
  );
  const [failureMessage, setFailureMessage] = useState(defaultFailureMessage);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    setFooterType(FooterTypes.KIT);
    closeLoader();
  }, []);

  function submitForm(event) {
    event.preventDefault();
    const validation = validateForm();
    if (validation.isValid) {
      // proceed to submit form
      setDisableButton(true);
      axios
        .post(
          `https://contact-slick-packages.netlify.app/.netlify/functions/api/contact`,
          {
            name: name,
            emailAddress: email,
            subject: subject,
            message: message,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setSuccess(true);
          } else {
            setFailureMessage(defaultFailureMessage);
            setSuccess(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              setFailureMessage("Please enter a valid email address");
              setSuccess(false);
            } else {
              setFailureMessage(defaultFailureMessage);
              setSuccess(false);
            }
          }
        })
        .finally(() => setDisableButton(false));
    } else {
      setFailureMessage(validation.message);
      setSuccess(false);
    }
  }

  function validateForm() {
    if (email === "") {
      return { isValid: false, message: "Email address is required" };
    }
    if (message === "") {
      return { isValid: false, message: "Message is required" };
    }
    return { isValid: true, message: "Validation successful" };
  }

  function clearStatus() {
    setSuccess(null);
    setFailureMessage(defaultFailureMessage);
  }

  return (
    <div className="contactUsContainer">
      <Helmet>
        <title>
          Contact Slick Packages - Custom Packaging for Small Business
        </title>
        <meta
          property="og:title"
          content="Contact Slick Packages - Custom Packaging for Small Business"
        />
        <meta
          charSet="utf-8"
          content="Contact Slick Packages to buy custom box with insert, custom printed boxes no minimum, and custom printed food packaging."
        />
        <meta
          property="og:description"
          content="Contact Slick Packages to buy custom box with insert, custom printed boxes no minimum, and custom printed food packaging.Contact Slick Packages to buy custom box with insert, custom printed boxes no minimum, and custom printed food packaging."
        />
        <link rel="canonical" href="https://slickpackages.com/contact" />
        <meta property="og:url" content="https://slickpackages.com/contact" />
      </Helmet>
      <h1 className="h1">Contact Us</h1>
      <div className="contactFormWrapper">
        <div className="contactGraphic">
          <div className="graphicBG">
            <h2 className="h1">Get In Touch</h2>
            <p className="caption">OUR DOOR IS ALWAYS OPEN</p>
          </div>
        </div>
        <form className="contactForm" onSubmit={submitForm}>
          {success === true ? (
            <p className="status success">
              We got your message! Someone from our team will contact you
              shortly.
            </p>
          ) : success === false ? (
            <p className="status failure">{failureMessage}</p>
          ) : (
            <p className="status" />
          )}
          <p className={name === "" ? "label invisible" : "label visible"}>
            Name
          </p>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            onKeyUp={() => clearStatus()}
          />
          <p className={email === "" ? "label invisible" : "label visible"}>
            Email*
          </p>
          <input
            type="email"
            name="email"
            placeholder="Email*"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onKeyUp={() => clearStatus()}
            required={true}
          />
          <p className={subject === "" ? "label invisible" : "label visible"}>
            Subject
          </p>
          <input
            type="text"
            name="message"
            placeholder="Subject"
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
            onKeyUp={() => clearStatus()}
          />
          <p className={message === "" ? "label invisible" : "label visible"}>
            Message*
          </p>
          <textarea
            placeholder="Message*"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            onKeyUp={() => clearStatus()}
            required={true}
          />
          <button type="submit" disabled={disableButton}>
            Send
          </button>
        </form>
      </div>
      <div className="contactDetails">
        <span className="contactDetailsImage phone" />
        <div className="contactDetailsText">
          <p className="bold white left">+1 (251) 277-9434</p>
          <p className="bold white right">sales@slickpackages.com</p>
        </div>
        <span className="contactDetailsImage mail" />
      </div>
      <Social />
    </div>
  );
}
