import React, { useContext, useState } from "react";
import { UIContext, BackgroundType } from "../../context/UIContext";
import axios from "axios";
import QuoteIcon from "../../images/FloatingQuoteIcon";
import ChatIcon from "../../images/ChatIcon";
import QuoteIconClose from "../../images/FloatingQuoteIconClose";

import "./styles.css";

export default function QuoteModal() {
  const { isQuoteModalOpen, toggleQuoteModal, backgroundColor } = useContext(
    UIContext
  );

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dimensions, setDimensions] = useState("");
  const [type, setType] = useState("placeholder");
  const [dimensionUnit, setDimensionUnit] = useState("inches");
  const [quantity, setQuantity] = useState("");
  const [notes, setNotes] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [success, setSuccess] = useState(null);
  const [defaultFailureMessage] = useState(
    "Oh no! Something went wrong. Please try again or write to us at info@slickpackages.com"
  );
  const [failureMessage, setFailureMessage] = useState(defaultFailureMessage);
  const [disableButton, setDisableButton] = useState(false);

  function submitForm(event) {
    event.preventDefault();
    const validation = validateForm();
    if (validation.isValid) {
      // proceed to submit form
      setDisableButton(true);
      axios
        .post(
          `https://contact-slick-packages.netlify.app/.netlify/functions/api/get-quote`,
          {
            name: name,
            emailAddress: email,
            contactNumber: contactNumber,
            boxType: type,
            dimensions: dimensions,
            dimensionUnit: dimensionUnit,
            quantity: quantity,
            notes: notes,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setSuccess(true);
          } else {
            setFailureMessage(defaultFailureMessage);
            setSuccess(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              setFailureMessage("Please enter a valid email address");
              setSuccess(false);
            } else {
              setFailureMessage(defaultFailureMessage);
              setSuccess(false);
            }
          }
        })
        .finally(() => setDisableButton(false));
    } else {
      setFailureMessage(validation.message);
      setSuccess(false);
    }
  }

  function validateForm() {
    if (email === "") {
      return { isValid: false, message: "Email address is required" };
    }
    if (type === "placeholder") {
      return { isValid: false, message: "Box type is required" };
    }
    if (dimensions === "") {
      return { isValid: false, message: "Dimensions are required" };
    }
    if (quantity === "") {
      return { isValid: false, message: "Quantity is required" };
    }
    return { isValid: true, message: "Validation successful" };
  }

  function clearStatus() {
    setSuccess(null);
    setFailureMessage(defaultFailureMessage);
  }

  return (
    <>
      <div
        className="quoteIcon quoteIconTop"
        onClick={() => toggleQuoteModal()}
        onKeyUp={() => toggleQuoteModal()}
        role="button"
        tabIndex="0"
        aria-label="Get Quote"
        data-tooltip="Get a Quote"
      >
        {isQuoteModalOpen ? (
          <QuoteIconClose isdark={"false"} />
        ) : (
          <QuoteIcon
            isdark={backgroundColor === BackgroundType.DARK ? "true" : "false"}
          />
        )}
      </div>
      {/* <div
        className="chatIcon"
        aria-label="Chat on WhatsApp"
        data-tooltip="Chat on WhatsApp"
      >
        <a href="https://wa.me/+12512779434" target="blank">
          <ChatIcon
            isdark={backgroundColor === BackgroundType.DARK ? "true" : "false"}
          />
        </a>
      </div> */}
      <div
        className={
          isQuoteModalOpen
            ? "quoteModal openQuoteModal"
            : isQuoteModalOpen === false
            ? "quoteModal closeQuoteModal"
            : "quoteModal"
        }
      >
        <span
          className={
            isQuoteModalOpen
              ? "circularPattern circularPatternReveal"
              : "circularPattern circularPatternHide"
          }
        />
        <form className="quoteForm" onSubmit={submitForm}>
          <h2 className="h1 white">Get A Custom Quote</h2>
          <p className="bold canary centered">FOR FREE</p>
          {success === true ? (
            <p className="status success">
              Request received! Someone from our team will get back to you with
              a quote asap.
            </p>
          ) : success === false ? (
            <p className="status failure">{failureMessage}</p>
          ) : (
            <p className="status" />
          )}
          <p className={name === "" ? "label invisible" : "label visible"}>
            Name
          </p>
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            onKeyUp={() => clearStatus()}
          />
          <p className={email === "" ? "label invisible" : "label visible"}>
            Email*
          </p>
          <input
            type="email"
            name="email"
            placeholder="Email*"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onKeyUp={() => clearStatus()}
            required={true}
          />
          <p
            className={
              contactNumber === "" ? "label invisible" : "label visible"
            }
          >
            Contact Number
          </p>
          <input
            type="tel"
            name="phone"
            placeholder="Contact Number"
            value={contactNumber}
            onChange={(event) => setContactNumber(event.target.value)}
            onKeyUp={() => clearStatus()}
          />
          <p
            className={
              type === "placeholder" ? "label invisible" : "label visible"
            }
          >
            Package Type*
          </p>
          <select
            name="select"
            id="package-type"
            defaultValue={type}
            onChange={(event) => setType(event.target.value)}
            onBlur={(event) => setType(event.target.value)}
            className={type === "placeholder" ? "selectPlaceholder" : ""}
          >
            <option value="placeholder" disabled hidden>
              Pick a Type*
            </option>
            Pick a Type
            <option value="reverse-tuck-end">Reverse Tuck End</option>
            <option value="straight-tuck-end">Straight Tuck End</option>
            <option value="tuck-top-snap-lock-bottom">
              Tuck Top Snap Lock
            </option>
            <option value="tuck-top-auto-bottom">Tuck Top Auto Lock</option>
            <option value="display-box">Display</option>
            <option value="gable-box">Gable</option>
            <option value="sleeve-box">Sleeve</option>
            <option value="six-corner-box">Six Corner</option>
            <option value="four-corner-box">Four Corner</option>
            <option value="hanger-box">Hanger</option>
            <option value="other">Any Other</option>
          </select>
          <div className="row">
            <div className="left-column">
              <p
                className={
                  dimensions === "" ? "label invisible" : "label visible"
                }
              >
                Dimensions*
              </p>
              <div className="quoteRow">
                <input
                  type="text"
                  name="dimensions"
                  placeholder="Dimensions (l x w x h)*"
                  value={dimensions}
                  onChange={(event) => setDimensions(event.target.value)}
                  onKeyUp={() => clearStatus()}
                  required={true}
                />
                <select
                  name="dimension-select"
                  id="dimension-unit"
                  defaultValue={dimensionUnit}
                  onChange={(event) => setDimensionUnit(event.target.value)}
                  onBlur={(event) => setDimensionUnit(event.target.value)}
                  className={type === "placeholder" ? "selectPlaceholder" : ""}
                >
                  <option value="inches">inches</option>
                  <option value="centimeter">cm</option>
                  <option value="milimeter">mm</option>
                </select>
              </div>
            </div>
            <div className="right-column">
              <p
                className={
                  quantity === "" ? "label invisible" : "label visible"
                }
              >
                Quantity*
              </p>
              <input
                type="number"
                name="quantity"
                placeholder="Quantity*"
                value={quantity}
                onChange={(event) => setQuantity(event.target.value)}
                onKeyUp={() => clearStatus()}
                required={true}
              />
            </div>
          </div>
          <p className={notes === "" ? "label invisible" : "label visible"}>
            Notes
          </p>
          <textarea
            placeholder="Let us know what you need"
            value={notes}
            onChange={(event) => setNotes(event.target.value)}
            onKeyUp={() => clearStatus()}
          />
          <button type="submit" disabled={disableButton}>
            Send
          </button>
        </form>
      </div>
    </>
  );
}
