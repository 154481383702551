import React from "react";

function FloatingIconClose(props) {
  const fill = props.isdark === "true" ? "#ffffff" : "#4b3ed2";
  const stroke = props.isdark === "true" ? "#4b3ed2" : "#ffffff";
  return (
    <svg
      width={66}
      height={66}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#prefix__filter0_d)">
        <path d="M37 6a27 27 0 110 54 27 27 0 010-54z" fill={fill} />
      </g>
      <path
        d="M30.077 24.001a2 2 0 00-2.677 2.973l16.35 14.72a2 2 0 002.676-2.972l-16.35-14.72z"
        fill={stroke}
      />
      <path
        d="M27.72 39.212a2 2 0 102.83 2.828l15.556-15.556a2 2 0 00-2.829-2.829L27.721 39.211z"
        fill={stroke}
      />
      <defs>
        <filter
          id="prefix__filter0_d"
          x={0}
          y={0}
          width={66}
          height={66}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx={-2} dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default FloatingIconClose;
