import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { UIContext, FooterTypes } from "../../context/UIContext";

import { IndustryCollection } from "../../data/firebase";

import SubcategoryDetails from "../../components/SubcategoryDetails/SubcategoryDetails";

export default function SubCategoryDetails() {
  const { setFooterType, closeLoader } = useContext(UIContext);
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setFooterType(FooterTypes.SEARCH);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      closeLoader();
    }
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
    getSubCategory();
  }, [location.pathname]);

  function getSubCategory() {
    const path = location.pathname.split("/");
    let collection = null;
    if (path[1] === "byIndustry") {
      // fetch design box
      collection = IndustryCollection;
    }
    collection
      .doc(path[2])
      .get()
      .then((snapshot) => {
        if (!snapshot.exists) {
          history.push("/not-found");
        } else {
          const cat = snapshot.data();
          cat.subCategories = [];
          collection
            .doc(path[2])
            .collection("subCategories")
            .get()
            .then((subSnapshot) => {
              subSnapshot.docs.forEach((d) => {
                cat.subCategories.push({ id: d.id, details: d.data() });
              });
              setCategory(cat);
              setIsLoading(false);
            })
            .catch((error) => {
              console.error(error);
              history.push("/not-found");
            });
        }
      })
      .catch((error) => {
        console.error(error);
        history.push("/not-found");
      });
  }

  return isLoading ? (
    <div className="dummyProduct"></div>
  ) : (
    <>
      <Helmet>
        <title className="productTitle">{category.title}</title>
        <meta charSet="utf-8" content={category.meta} />
        <link rel="canonical" href={category.link} />
        <meta property="og:title" content={`${category.title}`} />
        <meta property="og:description" content={category.meta} />
        <meta property="og:url" content={category.link} />
      </Helmet>
      <SubcategoryDetails category={category} />
    </>
  );
}
